import React, {
	forwardRef,
	useRef,
	useContext,
	useState,
	useEffect,
} from "react";
import { useTranslations } from "hooks";
import { runAction } from "modules/utils";
import { PortalContext } from "contexts/PortalManager";
import toast from "react-hot-toast";
const MultiAddressSelector = forwardRef(
	(
		{
			addresses: prev_addresses,
			name = "",
			defaultValues,
			error = "",
			required = false,
			disabled = false,
			initiallySelectDisabled = false,
			onSelect = () => {},
			containerClassName = "",
		},
		ref
	) => {
		const { translate } = useTranslations();
		const { callAction } = useContext(PortalContext);
		const [addresses, setAddresses] = useState([]);
		const [data, setData] = useState([]);

		useEffect(() => {
			if (prev_addresses) {
				prev_addresses?.forEach((address) => {
					if (address) {
						setAddresses((prev) => {
							const newAddress =
								convertAddressToPostPayload(address);
							if (
								!prev.some(
									(addr) =>
										addr.id === newAddress.id &&
										addr.Level === newAddress.Level
								)
							) {
								return [...prev, newAddress];
							} else {
								return prev;
							}
						});
					}
				});
				setData((prev) => ({
					...prev,
					addresses: prev_addresses?.filter((address) => address),
				}));
			}
		}, [prev_addresses]);

		const editAddress = (address, formattedAddress, index) => {
			if (
				addresses.some(
					(addr, i) =>
						addr.id === address.id &&
						addr.Level === address.level &&
						i !== index
				)
			) {
				toast(translate("youHaveSelectedAnExistingAddress"), {
					duration: 2000,
					icon: "❗️",
				});
				return;
			}

			setAddresses((prev) =>
				prev.map((addr, i) =>
					i === index
						? {
								id: address.id,
								Level: address.level,
						  }
						: addr
				)
			);

			setData((prev) => ({
				...prev,
				addresses: prev.addresses.map((addr, i) =>
					i === index ? formattedAddress : addr
				),
			}));
		};

		function getAddressString(address) {
			if (address.child) {
				return `${address.name} > ${getAddressString(address.child)}`;
			} else {
				return address.name;
			}
		}

		function convertAddressToPostPayload(address) {
			if (address.child) {
				return convertAddressToPostPayload(address.child);
			} else {
				return {
					id: address.id,
					Level: address.level,
				};
			}
		}

		const selectAddress = (address, formattedAddress) => {
			if (
				addresses.some(
					(addr) =>
						addr.id === address.id && addr.Level === address.level
				)
			) {
				return;
			}

			setAddresses((prev) => [
				...prev,
				{
					id: address.id,
					Level: address.level,
				},
			]);

			setData((prev) => ({
				...prev,
				addresses: [
					...(prev.addresses ? prev.addresses : []),
					formattedAddress,
				],
			}));
		};

		const deleteSelectedAddress = (addressObject) => {
			const address = convertAddressToPostPayload(addressObject);
			const removedIndex = addresses.findIndex(
				(addr) => addr.id === address.id && addr.Level === address.Level
			);

			setAddresses((prev) => {
				return prev.filter(
					(addr) =>
						!(
							addr.id === address.id &&
							addr.Level === address.Level
						)
				);
			});

			setData((prev) => {
				const newAddresses = [...prev.addresses];
				if (removedIndex !== -1) {
					newAddresses.splice(removedIndex, 1);
				}
				return {
					...prev,
					addresses: newAddresses,
				};
			});
		};

		return (
			<div
				className={`group relative cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 my-1 flex flex-row space-between ${containerClassName}`}
			>
				<input
					type="text"
					className="sr-only"
					required={required}
					name={`${name}`}
					onChange={() => {}}
					value={JSON.stringify(addresses || [])}
				/>

				<div className="flex-1 text-slate-400">
					{data?.addresses ? (
						data?.addresses.map((address, index) => (
							<div
								key={index}
								className="flex justify-between items-center mb-1 pb-1 border-b border-gray-200"
							>
								<span
									onClick={(e) => {
										!disabled &&
											runAction(
												"addresses",
												"openAddressSelect",
												{
													callAction,
													data: {
														selected: address,
													},
													onSelect: ({
														selected,
														formatted,
													}) =>
														editAddress(
															selected,
															formatted,
															index
														),
												}
											);
									}}
									className="text-black"
								>
									{getAddressString(address)}
								</span>
								<button
									type="button"
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										!disabled &&
											deleteSelectedAddress(address);
									}}
									className="transform hover:scale-110 transition-transform"
								>
									<i className="ri-delete-bin-line text-lg "></i>
								</button>
							</div>
						))
					) : (
						<span>{translate("selectAddress")}</span>
					)}
					<div className="flex justify-center items-center">
						<button
							type="button"
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								!disabled &&
									runAction(
										"addresses",
										"openAddressSelect",
										{
											callAction,
											onSelect: ({
												selected,
												formatted,
											}) =>
												selectAddress(
													selected,
													formatted
												),
										}
									);
							}}
							className=" mt-2 mb-1 ml-3 bg-gray-200 focus:bg-gray-300 hover:bg-gray-300 text-gray-600 px-20 py-1 rounded-lg"
						>
							{translate("addNewAddress")}
							<i className="ri-map-pin-add-line pl-1"></i>
						</button>
					</div>
				</div>
			</div>
		);
	}
);
export default MultiAddressSelector;
