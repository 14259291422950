import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
} from "react";
import { Loading } from "components/ui/Interactive";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import { useCrud } from "hooks";
import {
	CheckBox,
	MultipleSelect,
	ReSelect,
	MultiAddressSelector,
} from "components/ui/Input";

import TaskGroupService from "modules/tasks/pages/Manage/services";
import TaskTypeService from "modules/tasks/pages/Manage/pages/TaskTypes/services";

const ProvisionalSettingsForm = forwardRef((props, ref) => {
	const { getOne } = useCrud(props.service);

	const [data, setData] = useState([]);
	const myForm = useRef(null);

	const [loading, setLoading] = useState(true);

	const [taskTypesOptions, setTaskTypesOptions] = useState([]);

	const taskGroupService = new TaskGroupService();
	const taskTypeService = new TaskTypeService();

	const fetchTaskTypes = (taskGroupId = -1) => {
		taskTypeService.setParent(taskGroupId);
		taskTypeService.getOptionsList().then((res) => {
			setTaskTypesOptions(res?.data);
		});
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);

				fetchTaskTypes(res?.taskGroup?.id);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className="w-full h-100 pb-10 overflow-y-visible">
				<Form ref={myForm}>
					<input type="hidden" name="id" value={data?.id || false} />
					<input
						type="hidden"
						name="type"
						value={props.service.getTypeId()}
					/>

					<FormEntry label="taskGroup">
						<ReSelect
							extraParams="HideAllItem=true"
							name="taskGroupId"
							defaultValue={data?.taskGroup}
							service={taskGroupService}
							onChange={(selected) => {
								fetchTaskTypes(selected);
							}}
						/>
					</FormEntry>
					<FormEntry label="taskTypes">
						<MultipleSelect
							noAllAtSubmit={true}
							hasAllOption={false}
							name="provisional.taskTypeIdsArray"
							options={taskTypesOptions}
							defaultValue={data?.provisional?.taskTypeIds}
						/>
					</FormEntry>
					<FormEntry label={"isEditableFromUser"}>
						<CheckBox
							name="provisional.isEditableFromUser"
							label="isEditableFromUser"
							selected={data?.provisional?.isEditableFromUser}
						/>
					</FormEntry>
					<FormEntry label="fromLocation">
						<MultiAddressSelector
							name="provisional.from"
							addresses={data?.provisional?.from}
						/>
					</FormEntry>
					<FormEntry label="toLocation">
						<MultiAddressSelector
							name="provisional.to"
							addresses={data?.provisional?.to}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default ProvisionalSettingsForm;
