import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useTranslations } from "hooks";
import { Button } from "components/ui/Input";
import { runAction } from "modules/utils";
import configs from "config/configs";

const MenuHeader = ({ children }) => {
	return <div className="text-md font-bold px-4 mt-6 mb-2">{children}</div>;
};

const getTenantSettings = async () => {
	try {
		const res = await runAction("tenants", "getSettings");
		return res ?? null;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

const TaskGroupSidebar = ({
	hash = "",
	toggled,
	setToggled = () => {},
	taskGroups = [],
	tabPath = "",
	newTaskGroupAction = () => {},
	editTaskGroupAction = () => {},
	taskGroupPermissions = {
		Create: false,
		Update: false,
	},
}) => {
	const { translate } = useTranslations();
	const parts = tabPath.split(/tg_\d+\//);
	const partAfterTgNumber = parts[1];
	const [settings, setSettings] = useState({
		maxTaskGroups: configs.defaultMaxTaskGroups,
	});

	useEffect(() => {
		getTenantSettings().then((res) => setSettings(res));
	}, []);

	// if (hash === "" || hash === null) {
	// 	hash = routes[0]?.items[0]?.hash;
	// }

	return (
		<Sidebar
			breakPoint="md"
			className="main-sidebar z-50"
			backgroundColor="#ffffff"
			customBreakPoint="640px"
			rootStyles={{
				// borderRight: "1px solid rgb(156 163 175)",
				height: "100%",
				zIndex: 20,
			}}
			// collapsed={false}
			toggled={toggled}
			onBackdropClick={() => setToggled(false)}
		>
			<div className="flex flex-col h-full">
				<Menu
					menuItemStyles={{
						button: ({ level, active, disabled }) => {
							// only apply styles on first level elements of the tree
							if (level === 0)
								return {
									"&:hover": {
										backgroundColor: "#f5f5f5",
										opacity: 0.8,
									},
									opacity: active ? 1 : 0.5,
								};
						},
					}}
				>
					<MenuHeader>{translate("taskGroups")}</MenuHeader>

					{taskGroups?.map((taskGroup) => {
						let taskGroupName = translate(taskGroup.name);
						if (taskGroupName.length > 22) {
							taskGroupName =
								taskGroupName.substring(0, 22) + "...";
						}

						return (
							<div key={taskGroup.hash}>
								<div className="h-3"></div>
								<MenuItem
									component={
										<Link
											to={
												taskGroup.hash +
												"/" +
												partAfterTgNumber
											}
										/>
									}
									active={hash === taskGroup.hash}
								>
									<div className="group flex flex-row items-center  cursor-pointer">
										<div
											style={{
												backgroundColor:
													taskGroup.color,
											}}
											className={`py-[18px] mr-3 rounded-sm px-[2px]`}
										/>

										<div className="flex flex-1 w-full flex-col">
											<div className="text-xs opacity-60">
												{translate(
													taskGroup.module.name
												)}
											</div>
											<div
												title={taskGroup.name}
												className="font-medium"
											>
												{taskGroupName}
											</div>
										</div>

										{hash === taskGroup.hash ? (
											taskGroupPermissions.Update && (
												<div
													className="cursor:pinter"
													onClick={() =>
														editTaskGroupAction(
															taskGroup
														)
													}
												>
													<i className="ri-pencil-line opacity-40 group-hover:opacity-100"></i>
												</div>
											)
										) : (
											<div className="pl-2">
												<i
													className={`${taskGroup.icon} text-xl opacity-80`}
												/>
											</div>
										)}
									</div>
								</MenuItem>
							</div>
						);
					})}
				</Menu>

				<div className="mt-auto pb-3">
					<div className="pb-1 pl-1">
						<span className="text-xs font-thin opacity-80">
							{taskGroups?.length ?? 0}/{settings?.maxTaskGroups}{" "}
							{translate("configured")}
						</span>
					</div>
					{taskGroupPermissions.Create && (
						<Button
							onClick={newTaskGroupAction}
							disabled={
								settings?.maxTaskGroups <= taskGroups?.length
							}
							className="w-full rounded-md"
						>
							{translate("newTaskGroup")}
						</Button>
					)}
				</div>
			</div>
		</Sidebar>
	);
};

export default TaskGroupSidebar;
