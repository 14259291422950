import { useTranslations } from 'hooks';
import { renderToString } from 'react-dom/server';
import BadgeRender from './components/BadgeRender';
import ItemListRender from './components/ItemListRender';

const BadgedListRender = ({
	items,
	displayMax = 2,
	itemLengthToTruncate = 12,
	itemClassName = '',
	containerClassName = 'flex flex-wrap',
}) => {
	const { translate } = useTranslations();
	if (!items || items.length === 0) return '';

	return (
		<div className={containerClassName}>
			{items
				?.map((item) => {
					return (
						<BadgeRender
							value={item}
							itemLengthToTruncate={itemLengthToTruncate}
							itemClassName={itemClassName}
						/>
					);
				})
				.splice(0, displayMax)}
			{items?.length > displayMax && (
				<div
					data-tooltip-variant='light'
					data-tooltip-html={renderToString(ItemListRender(items, itemClassName))}
					data-tooltip-id={`table-tooltip`}
					className='rounded-full px-3 mt-1 py-1.5 text-center text-xs hover:opacity-60'
				>
					{translate('viewAll')}
				</div>
			)}
		</div>
	);
};
export default BadgedListRender;
