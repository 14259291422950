import { useState, useEffect } from "react";
import { ReSelect } from "components/ui/Input";
import Item from "./Item";
import { useTranslations } from "hooks";
import { Tooltip } from "react-tooltip";
const Department = ({
	departments,
	options,
	disabled = false,
	isValid = () => {},
	inputPrefix = "",
}) => {
	const { translate } = useTranslations();

	const [selectedDepartments, setSelectedDepartments] = useState([]);
	const [expandMode, setExpandMode] = useState(1); // 1: Single expanding, 2: Multi expanding
	const [isExpanded, setIsExpanded] = useState(false);

	const removeSelectedDepartment = (index) => {
		setSelectedDepartments((prev) => {
			const newDepartments = [...prev];
			newDepartments.splice(index, 1);
			return newDepartments;
		});
	};

	const generateAll = () => {
		setSelectedDepartments(
			options.map((option) => {
				return {
					...option,
					isOpened: false,
					departmentsTo: options.map((option) => {
						return {
							id: option.value,
							name: option.label,
						};
					}),
				};
			})
		);
	};

	useEffect(() => {
		if (options) {
			// Create a lookup object for options
			const optionsLookup = options.reduce((acc, option) => {
				acc[option.value] = option;
				return acc;
			}, {});

			const tempDeps = departments?.reduce((acc, department, index) => {
				const departmentOption =
					optionsLookup[department?.department?.id];

				if (!departmentOption) {
					return acc;
				}

				const departmentsTo = department?.departmentsTo?.reduce(
					(acc, departmentTo) => {
						const departmentOption =
							optionsLookup[departmentTo?.id];

						if (!departmentOption) {
							return acc;
						}

						acc.push({
							id: departmentOption?.value,
							name: departmentOption?.label,
						});

						return acc;
					},
					[]
				);

				acc.push({
					departmentsTo,
					value: departmentOption?.value,
					label: departmentOption?.label,
					isOpened: index === 0,
				});

				return acc;
			}, []);

			setSelectedDepartments(tempDeps || []);
		}
	}, [departments, options]);

	useEffect(() => {
		if (isExpanded) {
			setExpandMode(2);
			setSelectedDepartments((prev) => {
				const newDepartments = prev.map((department) => {
					department.isOpened = true;
					return department;
				});
				return newDepartments;
			});
		} else {
			setSelectedDepartments((prev) => {
				const newDepartments = prev.map((department) => {
					department.isOpened = false;
					return department;
				});
				return newDepartments;
			});
		}

		if (expandMode === 1) {
			setSelectedDepartments((prev) => {
				const newDepartments = prev.map((department, index) => {
					if (0 === index) {
						department.isOpened = true;
					} else {
						department.isOpened = false;
					}
					return department;
				});
				return newDepartments;
			});
		}
	}, [expandMode, isExpanded]);

	const helpMessageHTML = `
  <div class="text-sm w-full sm:w-auto">
	<div class="text-lg font-bold mb-2">
		How to Use the Department Selection Feature
	</div>
	<div class="mb-2">
		This section enables you to establish relationships between departments.<br>
		Follow these steps to use it effectively:
	</div>
	<div class="mb-2">
		<b>Select a Parent Department:</b> Choose a parent department from the<br>
		dropdown menu <i>(From Location)</i>.
	</div>
	<div class="mb-2">
		<b>View Available Child Departments:</b> Once you've selected a parent<br>
		department, a list of potential child departments <i>(To Locations)</i> will appear.
	</div>
	<div class="mb-2">
		<b>Select Child Departments:</b> Choose one or more child departments.<br>
		These are the departments that the parent department is permitted<br>
		to navigate to.
	</div>
	<div class="mb-2">
		For example, if you select <u>Cardiology</u> as the parent department and<br>
		<u>Pediatry</u> and <u>Neurology</u> as the child departments, this setup<br>
		allows the Cardiology department to navigate to both Pediatry<br>
		and Neurology departments.
	</div>
	<div class="italic">
		This feature is useful for defining the pathways that a team can use to<br>
		transfer patients from the selected parent department <i>(From Location)</i><br>
		to the chosen child departments <i>(To Locations)</i>.
	</div>
  </div>`;

	return (
		<div
			className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 my-1 `}
		>
			<div className=" flex flex-row justify-end">
				<div
					className="flex justify-start pr-2  text-xl  text-gray-800 hover:text-gray-600 cursor-help"
					data-tooltip-html={helpMessageHTML}
					data-tooltip-content={"test"}
					data-tooltip-id="department-tooltip"
				>
					<i class="ri-information-line"></i>
				</div>
				{selectedDepartments?.length < 2 && (
					<div
						onClick={() => generateAll()}
						className="flex  pr-2 items-center text-red-700 cursor-pointer opacity:70 hover:opacity-100"
					>
						<i class="ri-bubble-chart-line"></i>
						<span className="text-xs pl-1">
							{translate("generateAll")}
						</span>
					</div>
				)}

				{expandMode === 2 && (
					<div
						onClick={() => {
							setIsExpanded(false);
							setExpandMode(1);
						}}
						className="flex  pr-2 items-center text-cyan-700 cursor-pointer opacity:70 hover:opacity-100"
					>
						<i class="ri-layout-row-fill"></i>
						<span className="text-xs pl-1">
							{translate("singleExpanding")}
						</span>
					</div>
				)}
				{expandMode === 1 && (
					<div
						onClick={() => setExpandMode(2)}
						className="flex  pr-2 items-center text-cyan-700 cursor-pointer opacity:70 hover:opacity-100"
					>
						<i class="ri-layout-row-line"></i>
						<span className="text-xs pl-1">
							{translate("multiExpanding")}
						</span>
					</div>
				)}

				{!isExpanded && (
					<div
						onClick={() => setIsExpanded(true)}
						className="flex  pr-2 items-center text-cyan-700 cursor-pointer opacity:70 hover:opacity-100"
					>
						<i class="ri-expand-up-down-line"></i>
						<span className="text-xs pl-1">
							{translate("expandAll")}
						</span>
					</div>
				)}
				{isExpanded && (
					<div
						onClick={() => setIsExpanded(false)}
						className="flex justify-end pr-2 items-center text-cyan-700 cursor-pointer opacity:70 hover:opacity-100"
					>
						<i class="ri-contract-up-down-line"></i>
						<span className="text-xs pl-1">
							{translate("collapseAll")}
						</span>
					</div>
				)}
			</div>

			{selectedDepartments?.map((department, index) => (
				<Item
					key={index}
					inputPrefix={inputPrefix}
					open={department.isOpened}
					index={index}
					setOpen={(isOpened) => {
						if (isOpened && expandMode === 1) {
							setSelectedDepartments((prev) => {
								const newDepartments = prev.map(
									(department, i) => {
										if (index === i) {
											department.isOpened = true;
										} else {
											department.isOpened = false;
										}
										return department;
									}
								);
								return newDepartments;
							});
						} else {
							setSelectedDepartments((prev) => {
								const newDepartments = prev.map(
									(department, i) => {
										if (index === i) {
											department.isOpened = isOpened;
										}
										return department;
									}
								);
								return newDepartments;
							});
						}
					}}
					department={department}
					disabled={disabled}
					onRemove={removeSelectedDepartment}
					isValid={(status) => {
						setSelectedDepartments((prev) => {
							const newDepartments = [...prev];
							newDepartments[index].isValid = status;
							return newDepartments;
						});

						if (
							selectedDepartments?.some(
								(department) => department.isValid === false
							)
						) {
							isValid(false);
						} else {
							isValid(true);
						}
					}}
					options={options}
				/>
			))}
			<ReSelect
				disabled={disabled}
				required={selectedDepartments?.length === 0 ? true : false}
				options={options?.filter((option) =>
					selectedDepartments?.every(
						(department) => department.value !== option.value
					)
				)}
				onRemove={removeSelectedDepartment}
				onSelect={(selected) => {
					setSelectedDepartments((prev) => {
						const departmentToAdd = options.filter(
							(item) => selected === item.value
						)[0];

						// Check if the department is already in the array
						if (
							prev?.some(
								(department) =>
									department.value === departmentToAdd.value
							)
						) {
							// If it is, return the previous state
							return prev;
						}

						// If it's not, add the department to the array
						return [
							...prev.map((department) => ({
								...department,
								isOpened: false,
							})),
							{ ...departmentToAdd, isOpened: true },
						];
					});
				}}
			/>
			<Tooltip
				className=" w-full sm:w-auto"
				style={{
					backgroundColor: "rgb(249 250 255)",
					color: "#0a0a0a",
					padding: "20px",
					zIndex: "9999",
					borderRadius: "10px",
					boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
				}}
				id="department-tooltip"
			/>
		</div>
	);
};

export default Department;
