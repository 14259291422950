import { useEffect, useMemo, useState } from 'react';
import TaskTemplateService from '../services';

import Card from 'components/ui/Card';
import { Table } from 'components/ui/Table';
import { CrudOperations } from 'hoc';
import { useCrud, useTranslations } from 'hooks';
import TaskTemplateForm from '../forms/taskTemplate.form';
import TaskTemplateModel from '../models';

const Tables = ({ taskGroupId = -1, permissions }) => {
	const service = useMemo(() => new TaskTemplateService(), []);
	const model = new TaskTemplateModel();
	service.setParent(taskGroupId);

	const { create, update, getExport, remove, getAll } = useCrud(service);
	const { translate } = useTranslations();
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		fetchAndLoad();
	}, [taskGroupId]);

	const fetchAndLoad = async (l_queryString = null) => {
		setIsLoading(true);
		const res = await getAll(l_queryString || queryString);
		setData(res);
		setIsLoading(false);
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: TaskTemplateForm,
		componentName: model.getModelName(),
		permissions: permissions,
	});

	return (
		<Card
			className='mt-2'
			collapsible
			defaultOpen={true}
			header={model.getPluralModelName()}
			headerButtonLabel={permissions.Create && `+ ${translate('createTaskTemplate')}`}
			headerButtonClick={() =>
				openCreate({
					taskGroupId: taskGroupId,
					requestReload: fetchAndLoad,
				})
			}
		>
			<Table
				isLoading={isLoading}
				onRowClick={(row) =>
					permissions.Update &&
					openEdit(row, {
						taskGroupId: taskGroupId,
						requestReload: fetchAndLoad,
					})
				}
				model={model}
				isExportable={permissions.Export}
				onExportClick={async (queryString) => {
					return await getExport(queryString, model.getPluralModelName());
				}}
				meta={data.meta}
				data={data.data}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
			></Table>
		</Card>
	);
};
export default Tables;
