import { useTranslations } from 'hooks';

/**Normally we expect the status to be translated by BE */
const StatusRender = ({ value, color }) => {
	const { translate } = useTranslations();
	const classes = {
		red: {
			bg: 'bg-red-100',
			circle: 'bg-red-500',
			text: 'text-red-700',
		},
		green: {
			bg: 'bg-green-100',
			circle: 'bg-green-500',
			text: 'text-green-700',
		},
		orange: {
			bg: 'bg-yellow-100',
			circle: 'bg-yellow-500',
			text: 'text-yellow-700',
		},
		gray: {
			bg: 'bg-gray-100',
			circle: 'bg-gray-500',
			text: 'text-gray-700',
		},
		purple: {
			bg: 'bg-purple-100',
			circle: 'bg-purple-500',
			text: 'text-purple-700',
		},
		lightBlue: {
			bg: 'bg-cyan-100',
			circle: 'bg-cyan-500',
			text: 'text-cyan-700',
		},
		blue: {
			bg: 'bg-blue-100',
			circle: 'bg-blue-500',
			text: 'text-blue-700',
		},
		lightGreen: {
			bg: 'bg-lime-100',
			circle: 'bg-lime-500',
			text: 'text-lime-700',
		},
	};

	const aliases = { yellow: 'orange', cyan: 'lightBlue', lime: 'lightGreen' };
	color = aliases[color] || color;

	const statusClass = classes[color] || classes.gray;

	return (
		<div className='flex items-center'>
			<div className={`${statusClass.bg} px-3 py-1 rounded-xl flex-row flex items-center`}>
				<div className={`inline-block ${statusClass.circle} rounded-full h-3 w-3 mr-2`}></div>
				<div className={`uppercase ${statusClass.text}`}>{translate(value, true)}</div>
			</div>
		</div>
	);
};
export default StatusRender;
