import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useTranslations } from "hooks";
import Tooltip from "components/ui/Input/internal/Tooltip";
import { getRandomColor } from "utils";

const ColorPicker = ({
	value,
	title = null,

	disabled = false,
	name = "",
	onChange = () => {},
}) => {
	const { translate } = useTranslations();
	const [displayColorPicker, setDisplayColorPicker] = useState(false);
	const [color, setColor] = useState(value || getRandomColor());

	useEffect(() => {
		setColor(value || getRandomColor());
	}, [value]);

	return (
		<Tooltip text={translate(title, true)}>
			<input type="hidden" name={name} value={color} />
			<div>
				<div
					className={`flex items-center ${
						disabled ? "cursor-not-allowed" : "cursor-pointer"
					}`}
					onClick={() => !disabled && setDisplayColorPicker(true)}
				>
					<div
						className="w-10 h-10 rounded-lg border border-gray-200 mr-1"
						style={{ backgroundColor: color }}
					></div>
					<i className="ri-arrow-down-s-line"></i>
				</div>
				{displayColorPicker ? (
					<div className="z-10">
						<div
							className="fixed inset-0 z-9"
							onClick={() => setDisplayColorPicker(false)}
						></div>
						<SketchPicker
							color={color}
							onChangeComplete={(color) => {
								setColor(color.hex);
								onChange(color.hex);
								setDisplayColorPicker(false);
							}}
						/>
					</div>
				) : null}
			</div>
		</Tooltip>
	);
};

export default ColorPicker;
