import {
	useImperativeHandle,
	forwardRef,
	useRef,
	useState,
	useEffect,
	useCallback,
} from "react";

import MatrixEdit from "components/ui/Matrix/MatrixEdit";
import MatrixLoading from "components/ui/Matrix/MatrixLoader";

import { loadDistances } from "modules/addresses/services/addresses";

const MatrixForm = forwardRef((props, ref) => {
	const [matrix, setMatrix] = useState({});
	const [siblings, setSiblings] = useState([]);
	const [loading, setLoading] = useState(true);
	const matrixRef = useRef(null);

	useImperativeHandle(ref, () => ({
		getData: () =>
			loading
				? null
				: mapMatrixToSibling(matrixRef?.current?.getValues()),
		clear: () => null,
	}));

	const load = useCallback(async () => {
		setLoading(true);
		const res = await loadDistances(props.id, props.level);

		const distances = res.data.distances;

		let childrenMap = {};
		res.data.distances.forEach((dist) => {
			childrenMap[dist.siblingId] = dist.siblingName;
			childrenMap[dist.siblingToId] = dist.siblingToName;
		});

		const children = Object.keys(childrenMap).map((key) => ({
			id: parseInt(key, 10),
			name: childrenMap[key],
		}));

		const labels = [];
		const matrix = {};
		children.forEach((child) => {
			labels.push({ id: child.id, name: child.name });
			matrix[child.id] = {};
			children.forEach((otherChild) => {
				if (child.id !== otherChild.id) {
					if (!(otherChild.id in matrix)) {
						matrix[otherChild.id] = {};
					}

					const ix = distances.filter(
						(d) =>
							(d.siblingId === child.id &&
								d.siblingToId === otherChild.id) ||
							(d.siblingId === otherChild.id &&
								d.siblingToId === child.id)
					);

					if (ix.length > 0) {
						matrix[child.id][otherChild.id] = ix[0].distance;
						matrix[otherChild.id][child.id] = ix[0].distance;
					} else {
						matrix[child.id][otherChild.id] = 0;
						matrix[otherChild.id][child.id] = 0;
					}
				}
			});
		});
		setSiblings(labels);
		setMatrix(matrix);
		setLoading(false);
	}, [props.level, props.id]);

	const mapMatrixToSibling = (matrix) => {
		const flattened = [];
		const addedPairs = new Set(); // Set to keep track of added pairs

		// Iterate over each ID in the matrix object
		for (let siblingId in matrix) {
			// Iterate over each siblingToId for the current siblingId
			for (let siblingToId in matrix[siblingId]) {
				// Check if the mirrored pair has already been added
				if (!addedPairs.has(`${siblingToId}-${siblingId}`)) {
					const distance = matrix[siblingId][siblingToId];

					// Add the current pair to the flattened array and set
					flattened.push({
						siblingId,
						siblingToId,
						distance: distance || 0,
					});
					addedPairs.add(`${siblingId}-${siblingToId}`);
				}
			}
		}

		return flattened;
	};

	useEffect(() => {
		load();
	}, [load]);

	// -----------------------------------
	// Effects
	// -----------------------------------

	return (
		<>
			<div className="w-full h-100 flex flex-row">
				{loading ? (
					<MatrixLoading />
				) : (
					<MatrixEdit
						ref={matrixRef}
						values={matrix}
						items={siblings}
					/>
				)}
			</div>
		</>
	);
});

export default MatrixForm;
