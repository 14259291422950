import { CheckBox } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import { useEffect, useState } from 'react';

const Permission = ({ permissionKey, index, permissions, activePermissions }) => {
	const [checkboxes, setCheckboxes] = useState({});
	const { translate } = useTranslations();

	const handleCheckboxChange = (event) => {
		const eventName = event.target.name.split('__');
		if (eventName.length !== 2) return;
		const permissionValue = eventName[1];
		const permissionKey = eventName[0];

		if (permissionKey.includes('TaskAction') && !permissionKey.includes('ComplexTaskAction')) return;

		if (event.target.checked) {
			if (permissionValue === '2') {
				setCheckboxes({
					...checkboxes,
					[permissionKey + '__1']: event.target.checked,
					[permissionKey + '__2']: event.target.checked,
				});
			} else if (permissionValue === '4') {
				setCheckboxes({
					...checkboxes,
					[permissionKey + '__1']: event.target.checked,
					[permissionKey + '__2']: event.target.checked,
					[permissionKey + '__4']: event.target.checked,
				});
			} else if (permissionValue === '16') {
				setCheckboxes({
					...checkboxes,
					[permissionKey + '__1']: event.target.checked,
					[permissionKey + '__16']: event.target.checked,
				});
			}
		} else {
			if (permissionValue === '1') {
				setCheckboxes({
					...checkboxes,
					[permissionKey + '__1']: event.target.checked,
					[permissionKey + '__2']: event.target.checked,
					[permissionKey + '__4']: event.target.checked,
					[permissionKey + '__16']: event.target.checked,
				});
			} else if (permissionValue === '2') {
				setCheckboxes({
					...checkboxes,
					[permissionKey + '__2']: event.target.checked,
					[permissionKey + '__4']: event.target.checked,
				});
			}
		}
	};

	useEffect(() => {
		if (activePermissions) {
			const permissionValue = activePermissions[permissionKey];
			if (!permissionValue) return;

			const permissionValueBinary = permissionValue.toString(2);
			const permissionValueBinaryArray = permissionValueBinary.split('').reverse();

			let newCheckboxes = {};
			Object.keys(permissions[permissionKey]).forEach((permissionItemKey, permissionIndex) => {
				const permissionValue = permissions[permissionKey][permissionItemKey];
				const keyName = permissionKey + '__' + permissionValue;
				newCheckboxes[keyName] = permissionValueBinaryArray[permissionIndex] === '1';
			});
			setCheckboxes(newCheckboxes);
		}
	}, [activePermissions]);

	return (
		<div className='pl-2 py-2 border-b border-gray-200 ' key={index}>
			<div className='grid grid-cols-4 items-center gap-5'>
				<div>
					<p className='text-sm font-semibold text-gray-900'>{translate(permissionKey)}</p>
				</div>
				<div className=''>
					<div className='grid grid-flow-col auto-cols-max gap-4'>
						{Object.keys(permissions[permissionKey]).map((permissionItemKey, permissionIndex) => {
							const permissionValue = permissions[permissionKey][permissionItemKey];

							const keyName = permissionKey + '__' + permissionValue;
							return (
								<div key={permissionIndex}>
									<CheckBox
										checkboxSize='text-xl'
										labelOnNewLine
										label={translate(permissionItemKey)}
										name={keyName}
										selected={checkboxes[keyName] || false}
										onChange={handleCheckboxChange}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Permission;
