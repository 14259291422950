import React, { forwardRef } from "react";

import { useTranslations } from "hooks";
import Tooltip from "components/ui/Input/internal/Tooltip";
const Select = forwardRef((props, ref) => {
	const { translate } = useTranslations();
	const { disabled, title, ...elementProps } = { ...props };
	delete elementProps.options;
	return (
		<Tooltip text={translate(title, true)}>
			<div className={`w-full ${props.containerClassName} py-1  `}>
				{props.label && (
					<label
						className={`block mb-2 text-sm font-medium text-gray-900`}
					>
						{typeof props.label === "string"
							? translate(props.label, true)
							: props.label}
						{props.label}
					</label>
				)}
				<select
					ref={ref}
					{...elementProps}
					disabled={disabled}
					className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
						props.className
					} ${disabled ? "cursor-not-allowed" : "cursor-default"}`}
				>
					{props.options.map(({ value, label }, index) => (
						<option
							key={value}
							value={value}
							selected={value === props?.selectedValue}
						>
							{translate(label || "", true)}
						</option>
					))}
				</select>
			</div>
		</Tooltip>
	);
});

Select.defaultProps = {
	label: null,
	onSelect: () => {},
	className: "",
	containerclassname: "",
	selectedValue: null,
};

export default Select;
