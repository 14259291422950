import { Form, FormDivider, FormEntry } from 'components/form';
import { Input, MultiAddressSelector } from 'components/ui/Input';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { useCrud, useTranslations } from 'hooks';

const DepartmentForm = forwardRef((props, ref) => {
	const { translate } = useTranslations();
	const { getOne } = useCrud(props.service);

	const [data, setData] = useState([]);
	const [addresses, setAddresses] = useState([]);
	const myForm = useRef(null);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
	};

	useImperativeHandle(ref, () => ({
		getData,
		clear: resetData,
	}));

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);

				res.addresses?.map((address) =>
					setAddresses((prev) => {
						const newAddress = convertAddressToPostPayload(address);
						if (!prev.some((addr) => addr.id === newAddress.id && addr.Level === newAddress.Level)) {
							return [...prev, newAddress];
						} else {
							return prev;
						}
					}),
				);
			});
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	function convertAddressToPostPayload(address) {
		if (address.child) {
			return convertAddressToPostPayload(address.child);
		} else {
			return {
				id: address.id,
				Level: address.level,
			};
		}
	}

	return (
		<Suspense fallback={<>Loading</>}>
			<div className='w-full h-100 pb-10 overflow-y-auto'>
				<FormDivider>{translate('generalInformation')}</FormDivider>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />
					<input type='hidden' name='addresses' value={JSON.stringify(addresses || [])} />
					<FormEntry required label={'departmentName'}>
						<Input
							type='text'
							required={true}
							name={'name'}
							placeholder={'departmentName'}
							defaultValue={data?.name || ''}
						/>
					</FormEntry>
					<FormEntry label={'description'}>
						<Input
							type='text'
							required={false}
							name={'description'}
							placeholder={'description'}
							defaultValue={data?.description || ''}
						/>
					</FormEntry>
					<FormEntry label='locations'>
						<MultiAddressSelector name='addresses' addresses={data?.addresses} />
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default DepartmentForm;
