import { forwardRef, useRef } from 'react';

import Tooltip from 'components/ui/Input/internal/Tooltip';
import { useTranslations } from 'hooks';

const FileInput = forwardRef((props, ref) => {
	const { translate } = useTranslations();

	let inputRef = useRef(null);
	if (ref) {
		inputRef = ref;
	}

	return (
		<Tooltip text={translate(props?.title ?? null, true)}>
			<div className={`relative w-full ${props.containerClassName}`}>
				{!props.hideLabel && props.label && (
					<label className={`block mb-1 text-xs font-medium text-gray-700`}>
						{translate(props?.label || '', true)}
					</label>
				)}
				<div className='flex flex-row items-center'>
					<input
						type='file'
						ref={inputRef}
						{...props}
						placeholder={translate(props?.placeholder || '', true)}
						className={`
							cursor-pointer
							bg-gray-50 
							border 
							border-gray-300 
							text-gray-900 
							text-sm rounded-lg 
							focus:ring-blue-500 
							focus:border-blue-500 
							block w-full 
							p-2.5 
							my-1 
							file:cursor-pointer
							file:mr-5 file:py-2 file:px-3 file:border-0
							file:rounded-lg
							file:bg-primary-500 file:text-white
							${props.className}
						`}
						// class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none"
					/>
				</div>
			</div>
		</Tooltip>
	);
});

FileInput.defaultProps = {
	className: '',
	hideLabel: false,
};

export default FileInput;
