import constants from './constants';

const STORAGE_KEY = constants.STORAGE_KEY;

const localStorageKeys = {
	TASK_MODULE_STORAGE_KEY: `${STORAGE_KEY}::task-module`,
	TASK_MODULE_OPTIONS_STORAGE_KEY: `${STORAGE_KEY}::task-module-options`,
	PERMISSIONS_STORAGE_KEY: `${STORAGE_KEY}::permissions`,
	USER_STORAGE_KEY: `${STORAGE_KEY}::user`,
	USER_PERMISSIONS_STORAGE_KEY: `${STORAGE_KEY}::user-permissions`,
	TRANSLATION_LANGUAGE_CODE_STORAGE_KEY: `${STORAGE_KEY}::translation-language-code`,
	LANGUAGES_STORAGE_KEY: `${STORAGE_KEY}::languages`,
	TEMPORARY_ADMIN_STORAGE_KEY: `${STORAGE_KEY}::temporary-admin`,
	USER_PERMISSIONS_TEMP_STORAGE_KEY: `${STORAGE_KEY}::user-permissions-temp`,
	TENANT_ENUMS_STORAGE_KEY: `${STORAGE_KEY}::tenant-enums`,
	TENANT_SETTINGS_STORAGE_KEY: `${STORAGE_KEY}::tenant-settings`,
	TENANT_CONSTANTS_STORAGE_KEY: `${STORAGE_KEY}::tenant-constants`,
	TENANT_VERSION_STORAGE_KEY: `${STORAGE_KEY}::tenant-version`,
	TENANT_INPUT_TYPES_STORAGE_KEY: `${STORAGE_KEY}::tenant-input-types`,
	TASK_ACTIONS_STORAGE_KEY: `${STORAGE_KEY}::task-actions`,
	TASK_STATUSES_STORAGE_KEY: `${STORAGE_KEY}::task-statuses`,
};

export default localStorageKeys;
