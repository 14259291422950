import tokenService from "services/token/token.service";
import { localStorageKeys } from "config";
import {
	getMyUser,
	getMyProfiles,
} from "../../../../../../../../auth/services/auth";
import UserService from "../services";

export const impersonateUser = async (userId) => {
	const service = new UserService();
	const tokenInformation = await service.impersonate(userId);

	const userRaw = await getMyUser(tokenInformation.data.token);
	const user = userRaw.data;
	let settings = user.settings;
	delete user.settings;
	let profiles = await getMyProfiles(tokenInformation.data.token);

	const adminUser = tokenService.getUserObject();
	tokenService.removeUser();
	tokenService.setUser({
		...tokenInformation.data,
		user,
		settings,
		profiles: profiles?.data || [],
	});

	await clonePermissionsLocalStorage();
	await storeAdminUserTemporarily(adminUser);
	window.dispatchEvent(new Event("profileSwitch"));
	return;
};

export const revertAdminUser = () => {
	const adminUser = JSON.parse(
		localStorage.getItem(localStorageKeys.TEMPORARY_ADMIN_STORAGE_KEY)
	);
	tokenService.setUser(adminUser);
	window.dispatchEvent(new Event("profileSwitch"));
	localStorage.removeItem(localStorageKeys.TEMPORARY_ADMIN_STORAGE_KEY);
};

const clonePermissionsLocalStorage = async () => {
	const permissions = JSON.parse(
		localStorage.getItem(localStorageKeys.USER_PERMISSIONS_STORAGE_KEY)
	);
	localStorage.setItem(
		localStorageKeys.USER_PERMISSIONS_TEMP_STORAGE_KEY,
		JSON.stringify(permissions)
	);
};

const storeAdminUserTemporarily = async (user) => {
	localStorage.setItem(
		localStorageKeys.TEMPORARY_ADMIN_STORAGE_KEY,
		JSON.stringify(user)
	);
};
