import { useEffect } from "react";
import usePermissions, { RESOURCE, PERMISSION } from "hooks/usePermissions";

import { useToolbar } from "hooks";
import { withSuspense } from "hoc";
import PatientTable from "./parts/PatientTable";
const Patients = () => {
	const { crumbsBuilder } = useToolbar();
	const { hasPermission } = usePermissions();

	useEffect(() => {
		crumbsBuilder.addPath("/persons/patients", "patients");

		crumbsBuilder.build();
	}, []);

	const permissionMap = {
		Patients: {
			View: hasPermission(RESOURCE.Patient, PERMISSION.View),
			Create: hasPermission(RESOURCE.Patient, PERMISSION.Create),
			Update: hasPermission(RESOURCE.Patient, PERMISSION.Update),
			Delete: hasPermission(RESOURCE.Patient, PERMISSION.Delete),
		},
	};

	return (
		<div className="grid grid-cols-1 gap-2">
			<PatientTable permissions={permissionMap.Patients} />
		</div>
	);
};

export default withSuspense(Patients);
