import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
} from "react";
import { Loading } from "components/ui/Interactive";
import { CheckBox, Input } from "components/ui/Input";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import { useTranslations, useCrud } from "hooks";

const ADSetupForm = forwardRef((props, ref) => {
	const { translate } = useTranslations();
	const { getOne } = useCrud(props.service);
	const [data, setData] = useState([]);
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);

	const initiateData = () => {
		getOne("")
			.then((response) => {
				setData(response);
				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setLoading(false);
			});
	};

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	useEffect(() => {
		initiateData();
	}, []);

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className="w-full h-100 pb-10 overflow-y-auto">
				<Form ref={myForm}>
					<InternalFormDivider>
						{translate("connection")}
					</InternalFormDivider>
					<FormEntry required label={"port"}>
						<Input
							placeholder={"i.e. 389"}
							type="number"
							min={1}
							required={true}
							name="port"
							defaultValue={data?.port || ""}
						/>
					</FormEntry>
					<FormEntry required label={"DC"}>
						<Input
							placeholder={"i.e. local"}
							type="text"
							required={true}
							name="dc"
							defaultValue={data?.dc || ""}
						/>
					</FormEntry>
					<FormEntry required label={"sharedSecret"}>
						<Input
							placeholder={"i.e. al0ngc0mpl3xstr1ng"}
							type="text"
							required={true}
							name="sharedSecret"
							defaultValue={data?.sharedSecret || ""}
						/>
					</FormEntry>
					<FormEntry required label={"bindPassword"}>
						<Input
							placeholder={"i.e. c0mplexP@ssw0rd"}
							type="text"
							required={true}
							name="bindPassword"
							defaultValue={data?.bindPassword || ""}
						/>
					</FormEntry>
					<FormEntry required label={"bindUser"}>
						<Input
							placeholder={"i.e. someuser"}
							type="text"
							required={true}
							name="bindUser"
							defaultValue={data?.bindUser || ""}
						/>
					</FormEntry>
					<FormEntry required label={"server"}>
						<Input
							placeholder={"i.e. link-to-ad.com"}
							type="text"
							required={true}
							name="server"
							defaultValue={data?.server || ""}
						/>
					</FormEntry>
					<FormEntry required label={"isSyncEnabled"}>
						<CheckBox
							name="isSyncEnabled"
							label="isSyncEnabled"
							selected={data?.isSyncEnabled || false}
						/>
					</FormEntry>
					<FormEntry required label={"runIntervalInMinutes"}>
						<Input
							placeholder={"i.e. 5000"}
							type="number"
							min={1}
							required={true}
							name="runIntervalInMinutes"
							defaultValue={data?.runIntervalInMinutes || ""}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});
export default ADSetupForm;
