import { useTranslations } from 'hooks';
import usePermissions, { PERMISSION, RESOURCE } from 'hooks/usePermissions';
import tokenService from 'services/token/token.service';
import { impersonateUser } from '../utils';

const doImpersonate = async (userId) => {
	await impersonateUser(userId);
	window.location.reload();
};

const ImpersonateButton = ({ item }) => {
	const userId = item.id;
	const { translate } = useTranslations();
	const { hasPermission } = usePermissions();

	if ((hasPermission(RESOURCE.User, PERMISSION.Impersonate), !tokenService.hasTemporaryAdmin())) {
		return (
			<div
				data-tooltip-variant='light'
				data-tooltip-content={translate('impersonate')}
				data-tooltip-id={`table-tooltip`}
				data-tooltip-delay-show={500}
				className='flex justify-center items-center'
			>
				<button
					onClick={(e) => {
						e.stopPropagation();
						doImpersonate(userId);
					}}
					className='w-10 h-8 flex items-center justify-center rounded-lg border border-black hover:bg-black hover:bg-opacity-5 focus:ring-2 focus:ring-gray-400 active:bg-gray-400'
				>
					<i className='ri-spy-line'></i>
				</button>
			</div>
		);
	}
	return <></>;
};
export default ImpersonateButton;
