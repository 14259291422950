import { Form, FormEntry } from 'components/form';
import { Input, MultipleSelect } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useCrud } from 'hooks';
import TeamService from 'modules/persons/pages/Users/pages/TeamsAndDepartments/Teams/services';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import OptionsInput from '../../../components/OptionsInput';

const ActionForm = forwardRef((props, ref) => {
	const { getOne } = useCrud(props.service);
	const [data, setData] = useState([]);
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);

	const [teamOptions, setTeamOptions] = useState([]);

	const teamService = new TeamService();

	const fetchTeams = () => {
		teamService.getOptionsList().then((res) => {
			setTeamOptions(res?.data);
		});
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchTeams();
		initiateData();
	}, []);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />

					<FormEntry label='name' required>
						<Input
							type='text'
							name='name'
							defaultValue={data?.name || ''}
							required={true}
							placeholder={'name'}
						/>
					</FormEntry>

					<FormEntry required label='teams'>
						<MultipleSelect
							required={true}
							noAllAtSubmit={true}
							hasAllOption={false}
							name='teamIdsArray'
							options={teamOptions}
							defaultValue={data?.teams?.map((team) => team.id)}
						/>
					</FormEntry>
					<FormEntry label='requestForOverXMinutes'>
						<OptionsInput
							name='cancelAndCopy.requestForOverXMinutesJson'
							defaultValue={data?.cancelAndCopy?.requestForOverXMinutes}
							required={false}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default ActionForm;
