import AbstractService from "services/Service";
import { extractMeta } from "utils/pagination";
const endpoint = "taskgroups/{parentId}/types";

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
		this.parentId = null;
	}

	setParent(id) {
		this.endpoint = endpoint.replace("{parentId}", id);
		this.parentId = id;
	}

	beforeEach() {
		this.assertIfParentIdIsSet();
		return true;
	}

	assertIfParentIdIsSet() {
		if (this.parentId === null) {
			throw new Error("Parent ID is not set");
		}
	}

	mapData(data) {
		const {
			id,
			expectedTaskTime,
			anticipationTime,
			advancedRequestTime,
			enabled,
			...rest
		} = data;
		return {
			...rest,
			expectedTaskTime: expectedTaskTime
				? parseInt(expectedTaskTime)
				: null,
			anticipationTime: anticipationTime
				? parseInt(anticipationTime)
				: null,
			advancedRequestTime: advancedRequestTime
				? parseInt(advancedRequestTime)
				: null,
			enabled: enabled === "true",
		};
	}

	async getOptionsList(queryString = "") {
		if (this.parentId) {
			return await super.getOptionsList(queryString);
		}
		return {
			data: [],
			meta: extractMeta(
				{
					page: 1,
					pageSize: 10,
					data: [],
					message: "",
					succeeded: true,
				},
				queryString
			),
		};
	}

	async getMultiParentOptionsList(parentIds = []) {
		let options = [];
		for (let i = 0; i < parentIds.length; i++) {
			this.setParent(parentIds[i]);
			const { data } = await this.getOptionsList("Page=1&PageSize=200");
			options = [...options, ...data];
		}
		return options.filter(
			(option, index, self) =>
				self.findIndex((t) => t.value === option.value) === index
		);
	}
}

export default Service;
