import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
	useContext,
} from "react";
import { useTranslations, useCrud } from "hooks";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import {
	Input,
	Radio,
	ReSelect,
	TextArea,
	CheckBox,
} from "components/ui/Input";
import { Loading } from "components/ui/Interactive";

const TaskTypeForm = forwardRef((props, ref) => {
	const { getOne } = useCrud(props.service);
	const { translate } = useTranslations();

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const myForm = useRef(null);

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
	};

	useImperativeHandle(ref, () => ({
		getData,
		clear: resetData,
	}));

	useEffect(() => {
		initiateData();
	}, []);

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<div>Loading...</div>}>
			<div className="w-full h-100 pb-10 overflow-y-visible">
				<Form ref={myForm}>
					<InternalFormDivider>
						{translate("configuration")}
					</InternalFormDivider>
					<input type="hidden" name="id" value={data?.id || false} />
					<FormEntry label="name" required>
						<Input
							type="text"
							name="name"
							minLength={3}
							defaultValue={data?.name || ""}
							required={true}
							placeholder={"name"}
						/>
					</FormEntry>
					<FormEntry label="description">
						<TextArea
							rows={3}
							placeholder={"description"}
							name="description"
							defaultValue={data?.description || ""}
						/>
					</FormEntry>
					<FormEntry label="expectedTaskTime" required>
						<Input
							type="number"
							min={0}
							name="expectedTaskTime"
							defaultValue={data?.expectedTaskTime || null}
							required={true}
							placeholder={"expectedTaskTime"}
							sideLabel={"minutes"}
						/>
					</FormEntry>
					<FormEntry label="advancedRequestTime">
						<Input
							type="number"
							min={0}
							name="advancedRequestTime"
							defaultValue={data?.advancedRequestTime || null}
							placeholder={"advancedRequestTime"}
							sideLabel={"minutes"}
						/>
					</FormEntry>
					<FormEntry label="anticipationTime">
						<Input
							type="number"
							min={0}
							name="anticipationTime"
							defaultValue={data?.anticipationTime || null}
							placeholder={"anticipationTime"}
							sideLabel={"minutes"}
						/>
					</FormEntry>
					<FormEntry label={"enabled"}>
						<CheckBox
							disabled={data?.canBeDisabled === false}
							name="enabled"
							selected={data?.enabled === false ? false : true}
							label={"enabled"}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});
export default TaskTypeForm;
