import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
} from "react";
import { Loading } from "components/ui/Interactive";
import { Input, MultipleSelect } from "components/ui/Input";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import { useTranslations, useCrud } from "hooks";
import TaskGroupService from "modules/tasks/pages/Manage/services";
import InputTypes from "../forms/InputTypes.form";
import OptionInput from "../components/OptionInput";
import { useAside } from "hooks";

const CustomFieldForm = forwardRef((props, ref) => {
	const { helperAsideBuilder } = useAside();
	const { getOne } = useCrud(props.service);
	const { translate } = useTranslations();
	const [data, setData] = useState([]);
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);
	const taskGroupService = new TaskGroupService();
	const [taskGroupOptions, setTaskGroupOptions] = useState([]);
	const [activeInputType, setActiveInputType] = useState(null);

	const openDialog = () => {
		helperAsideBuilder.setTitle(translate("inputTypes"));
		helperAsideBuilder.setComponent(InputTypes);
		helperAsideBuilder.setOpen(true);
		helperAsideBuilder.setComponentProps({
			data: { activeInputType: activeInputType ?? data?.inputType },
			chooseInputType: (inputType) => chooseIT(inputType),
		});
		helperAsideBuilder.build();
	};

	const chooseIT = (inputType) => {
		setActiveInputType(inputType);
		helperAsideBuilder.setOpen(false);
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);
				setActiveInputType(res?.inputType);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
		taskGroupService.getOptionsList().then((res) => {
			setTaskGroupOptions(res.data);
		});
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className="w-full h-100 pb-10 overflow-y-visible">
				<Form ref={myForm}>
					<input type="hidden" name="id" value={data?.id || false} />

					<InternalFormDivider>
						{translate("configuration")}
					</InternalFormDivider>
					<FormEntry label="fieldName" required={true}>
						<Input
							type="text"
							placeholder={"customFieldName"}
							required={true}
							name="name"
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label="inputType" required>
						<div
							className=" relative bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 my-1 cursor-pointer "
							onClick={() => openDialog()}
						>
							<input
								type="text"
								name="InputType"
								required={true}
								className="sr-only"
								onChange={() => {}}
								value={activeInputType?.id}
							/>
							<div className="flex items-center justify-between">
								<div>{activeInputType?.name}</div>
								<div className="opacity-50 hover:opacity-100">
									<i className="ri-arrow-right-line"></i>
								</div>
							</div>
						</div>
					</FormEntry>
					{activeInputType?.requiresOptions && (
						<FormEntry label="fieldValue" required>
							<OptionInput
								name="value"
								required={true}
								defaultValue={data?.value}
							/>
						</FormEntry>
					)}

					<FormEntry label="availableForTaskGroups" required={true}>
						<MultipleSelect
							required={true}
							options={taskGroupOptions}
							name="taskGroupIds"
							value={data?.taskGroupIds}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default CustomFieldForm;
