import AbstractService from "services/Service";
const endpoint = "hl7";

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	async mapData(reqData) {
		const { allowedPatientClasses, ...data } = reqData;
		const result = await super.mapData(data);

		if (allowedPatientClasses) {
			result.allowedPatientClasses = JSON.parse(allowedPatientClasses);
		}

		return result;
	}

	async getSettings() {
		return await this.api.get(`${this.endpoint}/settings`);
	}

	async create(data) {
		await this.beforeEach();
		return await this.api.put(
			`${this.endpoint}/settings`,
			await this.mapData(data)
		);
	}
}
export default Service;
