import localforage from 'localforage';
import constants from './constants';

const configs = {
	displayWarningsForUnFoundTranslations: false,
	defaultMaxTaskGroups: 8,
	localforageConfig: {
		driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
		name: constants.STORAGE_KEY + '-localforage',
		storeName: constants.STORAGE_KEY + '-localforage-store',
		description: 'LocalForage database for ' + constants.STORAGE_KEY,
	},
};

export default configs;
