import AbstractService from "services/Service";

const endpoint = "taskgroups";

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	parseValue(value) {
		if (value === "null") return null;
		if (value === "undefined") return null;
		if (value === undefined) return null;
		if (value === "true") return true;
		if (value === "false") return false;

		if (isNaN(value)) return value;
		if (!isNaN(value)) return parseFloat(value);
	}

	mapData(data) {
		const { id, moduleId, ...rest } = data;

		let fields = Object.keys(rest).reduce((acc, key) => {
			const match = key.match(/fields\[(\d+)\]\.(.+)/);
			if (match) {
				const index = parseInt(match[1]);
				const fieldKey = match[2];
				acc[index] = { ...(acc[index] || {}), [fieldKey]: rest[key] };

				acc[index].staticFieldId = parseInt(acc[index].staticFieldId);
				if (
					acc[index]?.isHidden === undefined ||
					acc[index]?.isHidden === null
				) {
					acc[index].isHidden = false;
				} else {
					acc[index].isHidden = this.parseValue(acc[index].isHidden);
				}

				if (
					acc[index]?.defaultValue === undefined ||
					acc[index]?.defaultValue === ""
				) {
					acc[index].defaultValue = null;
				}

				delete rest[key];
			}

			return acc;
		}, []);

		fields = fields.map((field) => {
			let l_defaultValue = field.defaultValue;
			let l_isHidden = field.isHidden;
			if (field.defaultValue !== null) {
				if (field.staticFieldId === 5 || field.staticFieldId === 6) {
					const address = JSON.parse(field.defaultValue);
					l_defaultValue = {
						Level: address.level,
						Id: address.id,
					};
				} else l_defaultValue = this.parseValue(field.defaultValue);
			}

			if (
				field.isHidden === null ||
				field.isHidden === undefined ||
				field.isHidden === "null" ||
				isNaN(field.isHidden)
			) {
				l_isHidden = false;
			}
			return {
				...field,
				defaultValue:
					l_defaultValue !== null
						? JSON.stringify([l_defaultValue])
						: null,
				isHidden: l_isHidden,
			};
		});

		return { ...rest, moduleId: parseInt(moduleId), fields };
	}
}

export default Service;
