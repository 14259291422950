import React, { forwardRef, useState } from "react";
import CheckBox from "./CheckBox";
import { Each } from "utils/Each";

//Currently this component is being used only on task templates. This one should be advanced to be used on other places as well.
const CheckGroup = forwardRef(
	({ label, options = [], name = "", defaultValue = null }, ref) => {
		const [selectedOptions, setSelectedOptions] = useState(
			defaultValue ?? []
		);

		return (
			<>
				<input
					type="hidden"
					name={`${name}`}
					value={selectedOptions?.join(",")}
				/>
				<Each
					of={options}
					render={(option) => (
						<div>
							<CheckBox
								key={option.value}
								label={option.label}
								selected={defaultValue?.includes(option.value)}
								labelClass="text-xs"
								onChange={(e) => {
									if (e.target.checked) {
										setSelectedOptions([
											...selectedOptions,
											option.value,
										]);
									} else {
										setSelectedOptions(
											selectedOptions.filter(
												(item) => item !== option.value
											)
										);
									}
								}}
							/>
						</div>
					)}
				/>
			</>
		);
	}
);
export default CheckGroup;
