import { changeColorContrast } from 'utils/color';
const BadgeRender = ({ value, itemLengthToTruncate = 12, itemClassName }) => {
	const itemName =
		value.name.length > itemLengthToTruncate ? value.name.substring(0, itemLengthToTruncate) + '...' : value.name;
	return (
		<div className='flex items-center pl-1'>
			<div
				data-tooltip-variant='dark'
				data-tooltip-content={value.name.length > itemLengthToTruncate ? value.name : ''}
				data-tooltip-id={`table-tooltip`}
				className={`w-32 bg-gray-200 py-1 px-2 mt-1 font-medium justify-center text-gray-700 whitespace-nowrap
										rounded-xl flex-row flex items-center ${itemClassName}`}
				style={
					value.color
						? {
								backgroundColor: value.color + '80',
								color: changeColorContrast(value.color, -40),
						  }
						: {}
				}
			>
				{itemName}
			</div>
		</div>
	);
};
export default BadgeRender;
