import { useState, useCallback } from "react";

import { useAside, useTranslations } from "hooks";
import { Each } from "utils/Each";

import {
	loadTreeChildren,
	loadSingle,
	create,
	edit,
	deleteAddress,
	updateDistances,
} from "modules/addresses/services/addresses";

import { getTranslatedName, actionAndReload, requestDelete } from "./utils";

import AddressForm from "../../forms/address.form";

import Node from "./Node";
import { mapWithDistances } from "../../../../models/address.model";
import MatrixForm from "../../forms/matrix.form";

export const NodeControl = ({
	id,
	index,
	name,
	level,
	children,
	permissions,
	rootLevel,
	parentReload = () => {},
}) => {
	// ================================================
	// Hooks
	// ================================================
	const { translate } = useTranslations();
	const { asideBuilder } = useAside();

	// ================================================
	// State
	// ================================================
	const [self, setSelf] = useState({
		id,
		name,
		level,
	});
	const [tree, setTree] = useState(children);
	const [expand, setExpand] = useState(index === 0 && level === rootLevel);

	// ================================================
	// Component Services
	// ================================================
	const reload = useCallback(async () => {
		const res = await loadTreeChildren(id, level);
		setSelf({
			id: res.data?.id,
			name: res.data?.name,
			level: res.data?.level,
		});
		setTree(res.data?.children);
	}, [id, level]);

	const hardReload = useCallback(async () => {
		if (tree !== undefined) setTree(undefined);
		reload();
	}, [tree, reload]);

	const expandAndReload = useCallback(
		async (e) => {
			setExpand(e);

			if (e) {
				reload(); // Non blocking await
			}
		},
		[reload]
	);

	const createAndReload = useCallback(
		async (data) =>
			await actionAndReload(
				async () => await create[level - 1](id, mapWithDistances(data)),
				reload,
				translate,
				"ThisCreatedSuccessfully",
				level - 1
			),
		[id, level, reload, translate]
	);

	const deleteAndReload = useCallback(
		async () =>
			await actionAndReload(
				async () => await deleteAddress[level](id),
				parentReload,
				translate,
				"ThisRemovedSuccessfully",
				level
			),
		[id, level, parentReload, translate]
	);

	const editAndReload = useCallback(
		async (data) => {
			await actionAndReload(
				async () => await edit[level](id, mapWithDistances(data)),
				reload,
				translate,
				"ThisUpdatedSuccessfully",
				level
			);
		},
		[id, level, reload, translate]
	);

	const saveDistances = useCallback(
		async (data) => {
			await actionAndReload(
				async () => await updateDistances(id, level, data),
				() => {},
				translate,
				"ThisUpdatedSuccessfully",
				level
			);
		},
		[id, level, translate]
	);

	// ================================================
	// Asides
	// ================================================
	const openNewChild = () => {
		asideBuilder.setTitle(`New ${getTranslatedName(translate, level - 1)}`);
		asideBuilder.setComponent(AddressForm);
		asideBuilder.setComponentKey(`new-address-${level}-${new Date()}`);
		asideBuilder.setComponentProps({
			type: getTranslatedName(translate, level - 1),
			level: level - 1,
			siblings: tree,
		});
		asideBuilder.setSaveCallback(createAndReload);
		asideBuilder.setOpen(true);
		asideBuilder.build();
	};

	const openViewAndEdit = () => {
		asideBuilder.setTitle(
			`${getTranslatedName(translate, level)} - ${name}`
		);
		asideBuilder.setComponent(AddressForm);
		asideBuilder.setComponentKey(`edit-address-${id}`);
		asideBuilder.setComponentProps({
			id,
			name,
			type: getTranslatedName(translate, level),
			level,
			permissions,
			isEdit: true,
			load: loadSingle[level],
		});
		asideBuilder.setSaveCallback(editAndReload);
		asideBuilder.setOpen(true);
		asideBuilder.build();
	};

	const openMatrixEdit = () => {
		asideBuilder.setTitle(`${name} - ${translate("distanceTiming")}`);
		asideBuilder.setComponent(MatrixForm);
		asideBuilder.setFullScreen(true);
		asideBuilder.setComponentProps({
			id,
			level,
		});
		asideBuilder.setSaveCallback(saveDistances);
		asideBuilder.setOpen(true);
		asideBuilder.build();
	};

	return (
		<Node
			id={self.id}
			name={self.name}
			level={self.level}
			rootLevel={rootLevel}
			expand={expand}
			onExpandToggle={() => expandAndReload(!expand)}
			permissions={permissions}
			loading={tree === undefined}
			onAddOpen={openNewChild}
			onEditOpen={openViewAndEdit}
			onDelete={() =>
				requestDelete(translate, name, level, deleteAndReload)
			}
			onMatrixOpen={openMatrixEdit}
		>
			<Each
				of={tree}
				render={(item, index) => (
					<NodeControl
						{...item}
						index={index}
						rootLevel={rootLevel}
						permissions={permissions}
						parentReload={async () => hardReload()}
					/>
				)}
			/>
		</Node>
	);
};

export default NodeControl;
