import { useImperativeHandle, forwardRef, useRef, useMemo } from "react";
import PropTypes from "prop-types";

import { FormWrapper, FormDivider, FormEntry } from "..";
import { Input, TextArea, ReSelect, Radio } from "components/ui/Input";
import { useTranslations } from "hooks";

const inputs = {
	Input: Input,
	TextArea: TextArea,
	ReSelect: ReSelect,
	Radio: Radio,
};

const FormBuilder = forwardRef(
	(
		{
			schema,
			values = {},
			onSubmit,
			observedChange = (target, value, ref) => {},
			extraProps = {},
		},
		ref
	) => {
		const formRef = useRef(null);
		const allRefs = useRef([]);

		useImperativeHandle(ref, () => ({
			submit: () => submitForm(null),
			clear: () => formRef.current?.reset(),
		}));

		const allInputs = useMemo(
			() => schema.reduce((a, c) => (a = [...a, ...c.inputs]), []),
			[schema]
		);

		const { translate } = useTranslations();

		const submitForm = (e) => {
			// e.validate();
			e?.preventDefault();
			if (!formRef.current.checkValidity()) {
				formRef.current.reportValidity();
				return;
			}
			const formData = new FormData(formRef.current);
			const formDataObject = Object.fromEntries(formData.entries());

			let hasError = false;
			allInputs.forEach((input) => {
				if ("validate" in input) {
					const validation = input.validate(
						formDataObject[input.name]
					);
					if (validation !== true) {
						allRefs?.current?.[input.name]?.showError(validation);
						hasError = true;
					}
				}
			});

			if (hasError) {
				formRef.current.reportValidity();
				return;
			}

			// Map Values if needed
			allInputs.forEach((input) => {
				if ("mapValue" in input) {
					formDataObject[input.name] = input.mapValue(
						formDataObject[input.name]
					);
				}
			});

			onSubmit(formDataObject);
		};

		// ------ State Handler --------

		// ----- End State Handler -----

		// TODO: Fix {...f} with only allowed props
		return (
			<form className="" ref={formRef} onSubmit={submitForm}>
				{schema.map((group, index) => {
					return (
						<>
							<FormDivider className={index > 0 ? "mt-14" : ""}>
								{translate(group.group, true).toUpperCase()}
							</FormDivider>
							{group.description && (
								<div className="text-xs text-slate-500 mb-4 w-[60%]">
									{translate(group.description, true)}
								</div>
							)}
							<FormWrapper>
								{group.inputs.map((f) => {
									const Component = inputs[f.component];
									const validations = { ...f.validations };
									const getValue = () => {
										if (f.name in values)
											return values[f.name];
										return f?.defaultValue || null;
									};

									const extras = {};
									if (f?.propOptions) {
										extras["options"] =
											extraProps[f?.propOptions];
									}

									if (f?.notify_changes) {
										const dependentOnValue =
											formRef?.current?.[
												f?.dependentOptionsOn
											].value;
										extras["options"] =
											extraProps?.loadDependentOptions?.(
												f.name,
												dependentOnValue
											);
									}

									if (f?.observable) {
										extras["onChange"] = (e) =>
											f?.observable &&
											observedChange(
												f.name,
												f.extract(e),
												allRefs.current[f.name]
											);
									}

									return (
										<FormEntry
											label={f.label}
											required={validations.required}
										>
											<Component
												key={`form-${f.name}`}
												{...f}
												{...validations}
												{...extras}
												defaultValue={getValue()}
												hideLabel
												ref={(ref) => {
													allRefs.current[f.name] =
														ref;
												}}
											/>
											{f.bottomHelpLabel && (
												<div className="text-right text-xs mr-2">
													{f.bottomHelpLabel}
												</div>
											)}
										</FormEntry>
									);
								})}
							</FormWrapper>
						</>
					);
				})}
			</form>
		);
	}
);

const ValidationsSchema = {
	required: PropTypes.bool,
	minLength: PropTypes.number,
	maxLength: PropTypes.number,
	pattern: PropTypes.instanceOf(RegExp),
};

const InputSchema = {
	component: PropTypes.oneOf[Object.keys(inputs)],
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	sideLabel: PropTypes.string,
	defaultValue: PropTypes.string,
	placeholder: PropTypes.string,
	validations: PropTypes.shape(ValidationsSchema),
	validate: PropTypes.func.isRequired,
	extract: PropTypes.func.isRequired,
	bottomHelpLabel: PropTypes.string,
	propOptions: PropTypes.string,
	observable: PropTypes.bool,
	mapValue: PropTypes.func,
};

const GroupedInputSchema = {
	group: PropTypes.oneOfType([PropTypes.string, null]),
	inputs: PropTypes.arrayOf(InputSchema).isRequired,
};

FormBuilder.propTypes = {
	schema: PropTypes.arrayOf(GroupedInputSchema).isRequired,
	onSubmit: PropTypes.func.isRequired,
	values: PropTypes.object,
	extraProps: PropTypes.object,
};

export default FormBuilder;
