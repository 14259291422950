import { useState, useEffect } from "react";
import TranslationContext from "./TranslationContext";
import fallbackTranslations from "../../modules/global/modules/translations/elements/fallbackTranslations.json";

import { runAction } from "modules/utils";

const TranslationProvider = ({ children }) => {
	const [translations, setTranslations] = useState(null);
	const [activeLanguageCode, setActiveLanguageCode] = useState(null);

	const getLanguages = async () => {
		const languages = await runAction("translations", "getLanguages");
		return languages;
	};

	const changeLanguage = async (lCode) => {
		const languages = await getLanguages();
		let languageCode = lCode.toUpperCase();

		if (!languages.find((l) => l.languageCode === languageCode)) {
			console.error(
				`Language code ${languageCode} not found, using default language`
			);
			languageCode = "EN";
		}

		setActiveLanguageCode(languageCode);

		const translation = await runAction(
			"translations",
			"getTranslations",
			languageCode
		);
		setTranslations(translation);

		await runAction("translations", "setActiveLanguageCode", languageCode);
	};

	useEffect(() => {
		const fetchData = async () => {
			const l_activeLanguageCode = await runAction(
				"translations",
				"getActiveLanguageCode"
			);
			changeLanguage(l_activeLanguageCode);
		};
		fetchData();
	}, []);

	const contexts = {
		translations,
		changeLanguage,
		activeLanguageCode,
		fallbackTranslations,
		getLanguages,
	};

	return (
		<TranslationContext.Provider value={contexts}>
			{children}
		</TranslationContext.Provider>
	);
};

export default TranslationProvider;
