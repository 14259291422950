const RoomTypeColumns = [
	{
		key: "id",
		label: "id",
		sortKey: "Id",
	},
	{
		key: "name",
		label: "name",
		sortKey: "Name",
		filter: {
			type: "text",
			key: "Prefix",
		},
	},
];

export default RoomTypeColumns;
