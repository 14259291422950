import AbstractService from "services/Service";

const endpoint = "taskgroups/{parentId}/templates";

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
		this.parentId = null;
	}

	setParent(id) {
		this.endpoint = endpoint.replace("{parentId}", id);
		this.parentId = id;
	}

	async copy(id) {
		this.beforeEach();
		return await this.api.get(`${this.endpoint}/${id}/copy`);
	}

	beforeEach() {
		this.assertIfParentIdIsSet();
		return true;
	}

	assertIfParentIdIsSet() {
		if (this.parentId === null) {
			throw new Error("Parent ID is not set");
		}
	}

	#parseValue(value) {
		if (value === "null") return null;
		if (value === "undefined") return null;
		if (value === undefined) return null;
		if (value === "") return null;
		if (value === "true") return true;
		if (value === "false") return false;

		if (isNaN(value)) return value;
		if (!isNaN(value)) return parseFloat(value);
	}

	mapData(data) {
		const { id, ...rest } = data;

		let fields = Object.keys(rest).reduce((acc, key) => {
			const match = key.match(/fields\[(\d+)\]\.(.+)/);
			if (match) {
				const index = parseInt(match[1]);
				const fieldKey = match[2];
				acc[index] = { ...(acc[index] || {}), [fieldKey]: rest[key] };
				delete rest[key];
			}

			return acc;
		}, []);

		fields = fields?.map((field) => {
			Object.keys(field).forEach((key) => {
				field[key] = this.#parseValue(field[key]);
			});

			if (field.defaultValue === undefined || field.defaultValue === "") {
				field.defaultValue = null;
			} else {
				if (field.inputType === 16) {
					//if it is address input
					const address = JSON.parse(field.defaultValue);
					if (address) {
						field.defaultValue = {
							Level: address.level,
							Id: address.id,
						};
					}
				}

				//IF multiple select, or checkGroup explode the value and parse it
				if (field.inputType === 12 || field.inputType === 9) {
					if (field.defaultValue) {
						if (
							isNaN(field?.defaultValue) &&
							field.defaultValue?.includes(",")
						) {
							field.defaultValue = JSON.stringify(
								field.defaultValue?.split(",")
							);
						} else {
							field.defaultValue = JSON.stringify([
								field.defaultValue,
							]);
						}
					} else field.defaultValue = null;
				} else if (field.defaultValue !== null) {
					field.defaultValue = JSON.stringify([field.defaultValue]);
				} else field.defaultValue = null;
			}

			field.order = field.order + 1;

			delete field.inputType;
			return field;
		});

		return { ...rest, fields };
	}

	async getMultiParentOptionsList(parentIds = []) {
		let options = [];
		for (let i = 0; i < parentIds.length; i++) {
			this.setParent(parentIds[i]);
			const { data } = await this.getOptionsList("Page=1&PageSize=200");
			options = [...options, ...data];
		}
		return options
			.filter(
				(option, index, self) =>
					self.findIndex((t) => t.value === option.value) === index
			)
			.sort((a, b) => a.label.localeCompare(b.label));
	}
}

export default Service;
