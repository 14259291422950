import { FormEntry } from "components/form";
import { Input, Select, ReSelect } from "components/ui/Input";

const FormElement = ({
	label,
	name,
	type,
	placeholder = "",
	value = null,
	defaultValue = null,
	options = [],
	otherOptions = null,
	onChange,
	error,
	disabled = false,
}) => {
	return (
		<FormEntry label={label}>
			{type === "date" ? (
				<Input
					type="date"
					name={name}
					placeholder={placeholder}
					defaultValue={defaultValue || null}
					disabled={disabled}
					{...otherOptions}
				/>
			) : type === "select" ? (
				<ReSelect
					name={name}
					options={options}
					placeholder={placeholder}
					defaultValue={defaultValue || null}
					disabled={disabled}
					{...otherOptions}
				/>
			) : (
				<Input
					type={type}
					name={name}
					placeholder={placeholder}
					defaultValue={defaultValue || null}
					disabled={disabled}
					{...otherOptions}
				/>
			)}
		</FormEntry>
	);
};

export default FormElement;
