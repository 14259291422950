import AbstractService from "services/Service";
const endpoint = "assets";

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}
}
export default Service;
