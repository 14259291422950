import { localStorageKeys } from "config";

class TokenService {
	getLocalRefreshToken() {
		const user = JSON.parse(
			localStorage.getItem(localStorageKeys.USER_STORAGE_KEY)
		);
		return user?.refreshToken;
	}

	getLocalAccessToken() {
		const user = JSON.parse(
			localStorage.getItem(localStorageKeys.USER_STORAGE_KEY)
		);
		return user?.token;
	}

	updateLocalAccessToken(token, refreshToken = null, expireOn = null) {
		let user = JSON.parse(
			localStorage.getItem(localStorageKeys.USER_STORAGE_KEY)
		);
		user.token = token;
		user.refreshToken = refreshToken || user.refreshToken;
		user.expireOn = expireOn || user.expireOn;
		localStorage.setItem(
			localStorageKeys.USER_STORAGE_KEY,
			JSON.stringify(user)
		);
	}

	getUser() {
		const user = JSON.parse(
			localStorage.getItem(localStorageKeys.USER_STORAGE_KEY)
		);
		return user?.user;
	}

	getUserObject() {
		const user = JSON.parse(
			localStorage.getItem(localStorageKeys.USER_STORAGE_KEY)
		);
		return user;
	}

	getSettings() {
		const user = JSON.parse(
			localStorage.getItem(localStorageKeys.USER_STORAGE_KEY)
		);
		return user?.settings;
	}

	setProfiles(profiles) {
		this.updateUser("profiles", profiles);
	}

	getProfiles() {
		const user = JSON.parse(
			localStorage.getItem(localStorageKeys.USER_STORAGE_KEY)
		);
		return user?.profiles;
	}

	updateProfiles(profiles) {
		this.updateUser("profiles", profiles);
	}

	updateAllSettings(request) {
		const settings = this.getSettings();
		const newSettings = { ...settings, ...request };
		this.updateUser("settings", newSettings);
	}

	updateSettings(key, val) {
		const settings = this.getSettings();
		settings[key] = val;
		this.updateUser("settings", settings);
	}

	setUser(user) {
		localStorage.setItem(
			localStorageKeys.USER_STORAGE_KEY,
			JSON.stringify(user)
		);
	}

	updateUser(key, val) {
		let user = JSON.parse(
			localStorage.getItem(localStorageKeys.USER_STORAGE_KEY)
		);
		user[key] = val;
		this.setUser(user);
	}

	removeUser() {
		Object.keys(localStorageKeys).forEach((key) => {
			localStorage.removeItem(localStorageKeys[key]);
		});
	}

	hasTemporaryAdmin() {
		return !!localStorage.getItem(
			localStorageKeys.TEMPORARY_ADMIN_STORAGE_KEY
		);
	}

	revertToAdmin() {
		const adminUser = JSON.parse(
			localStorage.getItem(localStorageKeys.TEMPORARY_ADMIN_STORAGE_KEY)
		);
		this.setUser(adminUser);
		localStorage.removeItem(localStorageKeys.TEMPORARY_ADMIN_STORAGE_KEY);
		this.revertTempPermissions();

		window.dispatchEvent(new Event("profileSwitch"));
		return;
	}

	revertTempPermissions() {
		const permissions = JSON.parse(
			localStorage.getItem(
				localStorageKeys.USER_PERMISSIONS_TEMP_STORAGE_KEY
			)
		);
		localStorage.setItem(
			localStorageKeys.USER_PERMISSIONS_STORAGE_KEY,
			JSON.stringify(permissions)
		);
		localStorage.removeItem(
			localStorageKeys.USER_PERMISSIONS_TEMP_STORAGE_KEY
		);
	}
}

const tokenService = new TokenService();

export default tokenService;
