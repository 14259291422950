import { useEffect, useState, cloneElement, useMemo } from "react";
import routes from "./routes";
import LinkTabs from "./components/LinkTabs";
import { useLocation, useRoutes, useNavigate } from "react-router-dom";
import TaskGroupSidebar from "./components/TaskGroupSidebar";
import ToggleButton from "./components/SidebarToggleButton";
import { withSuspense, withPaddinglessLayout, CrudOperations } from "hoc";
import { useToolbar, useCrud, useTranslations } from "hooks";
import TaskGroupService from "./services";
import { stringToColorHex } from "utils";
import TaskGroupForm from "./forms/Taskgroup.form";
import usePermissions, { RESOURCE, PERMISSION } from "hooks/usePermissions";
// import CrudOperations from "./parts/CrudOperations";

const Manage = () => {
	const service = useMemo(() => new TaskGroupService(), []);
	const { getAll, create, update, remove } = useCrud(service);
	const { crumbsBuilder } = useToolbar();
	const { hasPermission } = usePermissions();

	const location = useLocation();
	const navigate = useNavigate();
	const { translate } = useTranslations();
	const pathName = location.pathname; // Use this to dynamically render pages
	const pathArray = location.pathname.split("/");
	const activeTaskGroup = pathArray[3] || "";

	const [data, setData] = useState([]);
	const [sidebarToggled, setSidebarToggled] = useState(false);

	const taskGroupPermissions = {
		Create: hasPermission(RESOURCE.TaskGroup, PERMISSION.Create),
		View: hasPermission(RESOURCE.TaskGroup, PERMISSION.View),
		Update: hasPermission(RESOURCE.TaskGroup, PERMISSION.Create),
		Delete: hasPermission(RESOURCE.TaskGroup, PERMISSION.Delete),
		Export: hasPermission(RESOURCE.TaskGroup, PERMISSION.Export),
	};

	const permissionsMap = {
		taskTemplates: {
			TaskTemplates: {
				Create: hasPermission(RESOURCE.Template, PERMISSION.Create),
				View: hasPermission(RESOURCE.Template, PERMISSION.View),
				Update: hasPermission(RESOURCE.Template, PERMISSION.Create),
				Delete: hasPermission(RESOURCE.Template, PERMISSION.Delete),
				Export: hasPermission(RESOURCE.Template, PERMISSION.Export),
			},
			Documentations: {
				Create: hasPermission(
					RESOURCE.Documentations,
					PERMISSION.Create
				),
				View: hasPermission(RESOURCE.Documentations, PERMISSION.View),
				Update: hasPermission(
					RESOURCE.Documentations,
					PERMISSION.Create
				),
				Delete: hasPermission(
					RESOURCE.Documentations,
					PERMISSION.Delete
				),
				Export: hasPermission(
					RESOURCE.Documentations,
					PERMISSION.Export
				),
			},
		},
		taskTypes: {
			TaskTypes: {
				Create: hasPermission(RESOURCE.TaskType, PERMISSION.Create),
				View: hasPermission(RESOURCE.TaskType, PERMISSION.View),
				Update: hasPermission(RESOURCE.TaskType, PERMISSION.Create),
				Delete: hasPermission(RESOURCE.TaskType, PERMISSION.Delete),
				Export: hasPermission(RESOURCE.TaskType, PERMISSION.Export),
			},
		},
	};
	let localRoutes = routes?.map((route) => {
		return {
			...route,
			path:
				activeTaskGroup !== ""
					? route.path.replace("{taskgroup}", activeTaskGroup)
					: route.path,
		};
	});

	//we have to set as active item the first one if the activeTaskGroup is removed
	const fetchAndLoad = async (resetActiveTaskGroup = false) => {
		const res = await getAll();
		res.data?.forEach((item) => {
			item.hash = "tg_" + item.id;
		});
		setData(res.data);

		if (resetActiveTaskGroup || activeTaskGroup === "") {
			let l_taskgroup = res.data[0]?.hash;
			if (l_taskgroup !== undefined) {
				const t_route = localRoutes[0]?.path.replace(
					resetActiveTaskGroup ? activeTaskGroup : "{taskgroup}",
					l_taskgroup
				);

				navigate(t_route, { replace: true });
			} else {
				navigate("/tasks/manage", { replace: true });
			}
		}
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: TaskGroupForm,
		componentName: "TaskGroup",
		permissions: taskGroupPermissions,
		fetchAndLoadParams: [true],
	});

	useEffect(() => {
		crumbsBuilder.addPath("/tasks", "tasks");
		crumbsBuilder.addCurrent("manage");
		crumbsBuilder.build();

		fetchAndLoad();
	}, []);

	const getIdOfTaskGroup = () => {
		return activeTaskGroup.split("_")[1];
	};

	// let localRoutes = [...routes];

	const routing = useRoutes([
		...localRoutes?.map((item, index) => {
			let path = item.path;
			let parts = path.split("/");
			let newPath =
				"/" + parts[parts.length - 2] + "/" + parts[parts.length - 1];
			const permissions = permissionsMap[item.key];
			const elementWithPermissions = cloneElement(item.element, {
				permissions,
				taskGroupId: getIdOfTaskGroup(),
			});

			return {
				...item,
				path: newPath,
				element: elementWithPermissions,
			};
		}),
	]);

	return (
		<div
			className="w-full h-full flex flex-row"
			style={{ height: "calc(100vh - 115px)" }}
		>
			<TaskGroupSidebar
				toggled={sidebarToggled}
				setToggled={(t) => setSidebarToggled(t)}
				hash={activeTaskGroup}
				tabPath={pathName}
				taskGroups={data}
				permissions={permissionsMap}
				newTaskGroupAction={() => {
					taskGroupPermissions.Create && openCreate();
				}}
				editTaskGroupAction={(taskgroup) => {
					taskGroupPermissions.Update && openEdit(taskgroup);
				}}
				taskGroupPermissions={taskGroupPermissions}
			/>

			<div className="sm:hidden">
				<ToggleButton onClick={() => setSidebarToggled(true)} />
			</div>
			{activeTaskGroup !== "" ? (
				<div className="p-4 w-full h-full overflow-y-auto">
					<div>
						{<LinkTabs activeItem={pathName} items={localRoutes} />}
						<div>{routing}</div>
					</div>
				</div>
			) : (
				//NO task group data DIV at the center of the screen
				<div className="w-full h-full flex flex-col items-center justify-center">
					<div className="text-2xl text-gray-500">
						{translate("pleaseInsertTaskGroup")}
					</div>
				</div>
			)}
		</div>
	);
};
export default withSuspense(withPaddinglessLayout(Manage));
