import React from "react";
import routes from "./routes";
import LinkTabs from "./components/LinkTabs";
import { useLocation, useRoutes, Navigate } from "react-router-dom";

import usePermissions, { RESOURCE, PERMISSION } from "hooks/usePermissions";
const User = () => {
	const location = useLocation();
	const pathName = location.pathname; // Use this to dynamically render pages

	const { hasPermission } = usePermissions();

	const permissionsMap = {
		users: {
			User: {
				Create: hasPermission(RESOURCE.User, PERMISSION.Create),
				View: hasPermission(RESOURCE.User, PERMISSION.View),
				Update: hasPermission(RESOURCE.User, PERMISSION.Create),
				Delete: hasPermission(RESOURCE.User, PERMISSION.Delete),
				Export: hasPermission(RESOURCE.User, PERMISSION.Export),
				Impersonate: hasPermission(
					RESOURCE.User,
					PERMISSION.Impersonate
				),
			},

			UserProfile: {
				Create: hasPermission(RESOURCE.Profile, PERMISSION.Create),
				View: hasPermission(RESOURCE.Profile, PERMISSION.View),
				Update: hasPermission(RESOURCE.Profile, PERMISSION.Create),
				Delete: hasPermission(RESOURCE.Profile, PERMISSION.Delete),
				Export: hasPermission(RESOURCE.Profile, PERMISSION.Export),
			},
		},
		teamsAndDepartments: {
			Team: {
				Create: hasPermission(RESOURCE.Team, PERMISSION.Create),
				View: hasPermission(RESOURCE.Team, PERMISSION.View),
				Update: hasPermission(RESOURCE.Team, PERMISSION.Create),
				Delete: hasPermission(RESOURCE.Team, PERMISSION.Delete),
				Export: hasPermission(RESOURCE.Team, PERMISSION.Export),
			},
			Department: {
				Create: hasPermission(RESOURCE.Department, PERMISSION.Create),
				View: hasPermission(RESOURCE.Department, PERMISSION.View),
				Update: hasPermission(RESOURCE.Department, PERMISSION.Create),
				Delete: hasPermission(RESOURCE.Department, PERMISSION.Delete),
				Export: hasPermission(RESOURCE.Department, PERMISSION.Export),
			},
		},
		settings: {
			General: {
				//TODO: Add permissions for the rest of the settings
				Overview: {
					View: true,
				},
				Lists: {
					View: true,
				},
				PermissionGroup: {
					Create: hasPermission(
						RESOURCE.Permission,
						PERMISSION.Create
					),
					View: hasPermission(RESOURCE.Permission, PERMISSION.View),
					Update: hasPermission(
						RESOURCE.Permission,
						PERMISSION.Create
					),
					Delete: hasPermission(
						RESOURCE.Permission,
						PERMISSION.Delete
					),
					AssignToUser: hasPermission(
						RESOURCE.Permission,
						PERMISSION.AssignToUser
					),
				},
			},
			ActiveDirectory: {
				Setup: {
					View: hasPermission(
						RESOURCE.ActiveDirectory,
						PERMISSION.View
					),
					Update: hasPermission(
						RESOURCE.ActiveDirectory,
						PERMISSION.Create
					),
				},
				Configuration: {
					Create: hasPermission(
						RESOURCE.ActiveDirectory,
						PERMISSION.Create
					),
					View: hasPermission(
						RESOURCE.ActiveDirectory,
						PERMISSION.View
					),
					Update: hasPermission(
						RESOURCE.ActiveDirectory,
						PERMISSION.Create
					),
					Delete: hasPermission(
						RESOURCE.ActiveDirectory,
						PERMISSION.Delete
					),
					Sync: hasPermission(
						RESOURCE.ActiveDirectory,
						PERMISSION.Sync
					),
				},
			},
		},
	};

	const checkNestedPermissions = (permissions) => {
		for (let key in permissions) {
			if (typeof permissions[key] === "boolean" && permissions[key]) {
				return true;
			} else if (typeof permissions[key] === "object") {
				if (checkNestedPermissions(permissions[key])) {
					return true;
				}
			}
		}
		return false;
	};

	let localRoutes = [...routes];

	localRoutes = localRoutes.filter((route) => {
		const permissions = permissionsMap[route.key];
		return checkNestedPermissions(permissions);
	});

	const routing = useRoutes([
		{ path: "/", element: <Navigate to={localRoutes[0]?.path} replace /> },
		...localRoutes.map((item, index) => {
			let path = item.path;
			let parts = path.split("/");
			let newPath = "/" + parts[parts.length - 1];

			const permissions = permissionsMap[item.key];
			const elementWithPermissions = React.cloneElement(item.element, {
				permissions,
			});

			return {
				...item,
				path: newPath,
				element: elementWithPermissions,
			};
		}),
	]);
	return (
		<div>
			{<LinkTabs activeItem={pathName} items={localRoutes} />}
			<div>{routing}</div>
		</div>
	);
};
export default User;
