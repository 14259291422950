import { useState } from 'react';

import Card from 'components/ui/Card';
import { Table } from 'components/ui/Table';

import UsersForm from '../forms/user.form';

import { useCrud, useTranslations } from 'hooks';

import { CrudOperations } from 'hoc';
import UserModel from '../models';

import UserService from '../services';

const Tables = ({ permissions }) => {
	const service = new UserService();
	const model = new UserModel();

	const { create, update, getAll, getExport } = useCrud(service);
	const { translate } = useTranslations();

	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState('');

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			const res = await getAll(l_queryString || queryString);
			setData(res);
		} catch (error) {
			console.error('Error on Get All request: ', error);
		}
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		fetchAndLoad,
		service,
		form: UsersForm,
		componentName: model.getModelName(),
		permissions: permissions,
	});

	return (
		<Card
			className='mt-2'
			collapsible
			defaultOpen={true}
			header={model.getPluralModelName()}
			headerButtonLabel={permissions.Create && `+ ${translate('newUser')}`}
			headerButtonClick={openCreate}
		>
			<Table
				model={model}
				onRowClick={(row) => permissions.Update && openEdit(row, { requestReload: fetchAndLoad })}
				onExportClick={async (queryString) => {
					return await getExport(queryString, model.getPluralModelName());
				}}
				isExportable={permissions.Export}
				meta={data.meta}
				data={data.data}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
			/>
		</Card>
	);
};

export default Tables;
