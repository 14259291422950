import { useEffect, useState } from "react";
import { CheckBox } from "components/ui/Input";

const FooterInput = ({
	isDisabled,
	isHidden,
	isRequired,
	isAlwaysRequired = false,
	isNeverRequired = false,
	formKey,
	setFieldRequired = () => {},

	onFieldDataChange = (fieldData) => {},
}) => {
	const [disabled, setDisabled] = useState(isDisabled);
	const [hidden, setHidden] = useState(isHidden);
	const [required, setRequired] = useState(isAlwaysRequired || isRequired);

	useEffect(() => {
		setDisabled(isDisabled);
	}, [isDisabled]);

	useEffect(() => {
		setHidden(isHidden);
	}, [isHidden]);

	useEffect(() => {
		setRequired(isRequired);
	}, [isRequired]);

	useEffect(() => {
		onFieldDataChange({
			isDisabled: disabled,
			isHidden: hidden,
			isRequired: required,
		});

		if (required) {
			if (disabled || hidden) {
				setFieldRequired(true);
			} else {
				setFieldRequired(false);
			}
		}
	}, [disabled, hidden, required]);

	return (
		<tr className="border-b border-gray-200">
			<td></td>
			<td>
				<div className="flex flex-row items-center pb-3 pt-1 justify-end">
					<CheckBox
						selected={isNeverRequired ? false : required}
						disabled={isAlwaysRequired || isNeverRequired}
						name={formKey + ".isRequired"}
						labelClass="text-xs"
						label={"required"}
						className={`${isNeverRequired ? "opacity-30 " : ""}`}
						onChange={(e) => {
							setRequired(e.target.checked);
						}}
					/>

					<CheckBox
						selected={disabled}
						name={formKey + ".isDisabled"}
						labelClass="text-xs"
						label={"disabled"}
						onChange={(e) => {
							setDisabled(e.target.checked);
						}}
					/>

					<CheckBox
						selected={hidden}
						name={formKey + ".isHidden"}
						labelClass="text-xs"
						label={"hidden"}
						onChange={(e) => {
							setHidden(e.target.checked);
						}}
					/>
				</div>
			</td>
		</tr>
	);
};
export default FooterInput;
