import { Form, FormEntry } from 'components/form';
import Card from 'components/ui/Card';
import { Button, CheckBox, Input } from 'components/ui/Input';
import { useCrud, useTranslations } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import HL7Service from '../HL7/services';
import MultiTextInput from './components/MultiTextInput';

const HL7SettingsView = ({ permissions }) => {
	const service = new HL7Service();
	const { create } = useCrud(service);
	const { translate } = useTranslations();

	const [settings, setSettings] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const myForm = useRef(null);

	const getSettings = async () => {
		setIsLoading(true);
		const response = await service.getSettings();
		if (response) {
			setSettings(response.data);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getSettings();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(myForm.current);
		const formDataObject = Object.fromEntries(formData.entries());
		setIsLoading(true);
		await postSettings(formDataObject, (res) => {
			setIsLoading(false);
			getSettings(false);
		});
	};

	const postSettings = async (data, onSuccess = () => {}) => {
		if (data) {
			return await create(data, {
				creationSuccessMessage: 'hl7SettingsUpdatedSuccessfully',
			}).then((res) => {
				onSuccess(res);
			});
		}
		return null;
	};

	return (
		<Card className='mt-2' collapsible header={'HL7Settings'} defaultOpen={true} isLoading={isLoading}>
			<div>
				<Form ref={myForm} onSubmit={(e) => handleSubmit(e)}>
					<FormEntry label={'enabled'}>
						<CheckBox name='enabled' label='enabled' selected={settings?.enabled} />
					</FormEntry>
					<FormEntry label={'manageTasks'}>
						<CheckBox name='manageTasks' label='manageTasks' selected={settings?.manageTasks} />
					</FormEntry>
					<FormEntry required label={'addressFormat'}>
						<Input
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3 '
							placeholder={'addressFormat'}
							required={true}
							name='addressFormat'
							defaultValue={settings?.addressFormat}
						/>
					</FormEntry>
					<FormEntry label={'processA04Messages'}>
						<CheckBox
							name='processA04Messages'
							label='processA04Messages'
							selected={settings?.processA04Messages}
						/>
					</FormEntry>
					<FormEntry label={'handlePreAdmissions'}>
						<CheckBox
							name='handlePreAdmissions'
							label='handlePreAdmissions'
							selected={settings?.handlePreAdmissions}
						/>
					</FormEntry>
					<FormEntry required label={'patientNameFormat'}>
						<Input
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3 '
							placeholder={'patientNameFormat'}
							required={true}
							name='patientNameFormat'
							defaultValue={settings?.patientNameFormat}
						/>
					</FormEntry>
					<FormEntry label={'useUtcFormat'}>
						<CheckBox name='useUtcFormat' label='useUtcFormat' selected={settings?.useUtcFormat} />
					</FormEntry>
					<FormEntry label={'applyA11MessagesOnA04'}>
						<CheckBox
							name='applyA11MessagesOnA04'
							label='applyA11MessagesOnA04'
							selected={settings?.applyA11MessagesOnA04}
						/>
					</FormEntry>
					<FormEntry required label={'processMessagesRunIntervalInSeconds'}>
						<Input
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'processMessagesRunIntervalInSeconds'}
							type='number'
							min={1}
							required={true}
							name='processMessagesRunIntervalInSeconds'
							defaultValue={settings?.processMessagesRunIntervalInSeconds}
							sideLabel={'sec'}
						/>
					</FormEntry>
					<FormEntry required label={'deleteOldMessagesRunIntervalInDays'}>
						<Input
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'deleteOldMessagesRunIntervalInDays'}
							type='number'
							min={1}
							required={true}
							name='deleteOldMessagesRunIntervalInDays'
							defaultValue={settings?.deleteOldMessagesRunIntervalInDays}
							sideLabel={'days'}
						/>
					</FormEntry>
					<FormEntry required label={'deleteOldPatientChangeRequestRunIntervalInDays'}>
						<Input
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'deleteOldPatientChangeRequestRunIntervalInDays'}
							type='number'
							min={1}
							required={true}
							name='deleteOldPatientChangeRequestRunIntervalInDays'
							defaultValue={settings?.deleteOldPatientChangeRequestRunIntervalInDays}
							sideLabel={'days'}
						/>
					</FormEntry>
					<FormEntry label={'changeProcessTime'}>
						<Input
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3 '
							placeholder={'changeProcessTime'}
							name='changeProcessTime'
							defaultValue={settings?.changeProcessTime}
						/>
					</FormEntry>
					<FormEntry label={'savePatientInsuranceInfo'}>
						<CheckBox
							name='savePatientInsuranceInfo'
							label='savePatientInsuranceInfo'
							selected={settings?.savePatientInsuranceInfo}
						/>
					</FormEntry>
					<FormEntry label={'isolationRegulationGroupId'}>
						<Input
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3 '
							placeholder={'isolationRegulationGroupId'}
							name='isolationRegulationGroupId'
							defaultValue={settings?.isolationRegulationGroupId}
						/>
					</FormEntry>
					<FormEntry label={'processMessageInAdvanceInDays'}>
						<Input
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'processMessageInAdvanceInDays'}
							type='number'
							min={1}
							required={false}
							name='processMessageInAdvanceInDays'
							defaultValue={settings?.processMessageInAdvanceInDays}
							sideLabel={'days'}
						/>
					</FormEntry>
					<FormEntry label={'allowedPatientClasses'}>
						<MultiTextInput
							containerClassName={'sm:w-full md:w-full lg:w-full xl:w-2/3'}
							name='allowedPatientClasses'
							defaultValue={settings?.allowedPatientClasses}
						/>
					</FormEntry>
					<FormEntry required label={'messageExpirationForTasksInHours'}>
						<Input
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'messageExpirationForTasksInHours'}
							type='number'
							min={0}
							required={true}
							name='messageExpirationForTasksInHours'
							defaultValue={settings?.messageExpirationForTasksInHours}
							sideLabel={'hours'}
						/>
					</FormEntry>
					<FormEntry label={'saveAdditionalPersonalPatientInfo'}>
						<CheckBox
							name='saveAdditionalPersonalPatientInfo'
							label='saveAdditionalPersonalPatientInfo'
							selected={settings?.saveAdditionalPersonalPatientInfo}
						/>
					</FormEntry>
					<FormEntry required label={'inactivePatientExpirationInMonths'}>
						<Input
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'inactivePatientExpirationInMonths'}
							type='number'
							min={0}
							required={true}
							name='inactivePatientExpirationInMonths'
							defaultValue={settings?.inactivePatientExpirationInMonths}
							sideLabel={'months'}
						/>
					</FormEntry>
					<FormEntry label={'processA03WithEvnEarlierThanA01A04'}>
						<CheckBox
							name='processA03WithEvnEarlierThanA01A04'
							label='processA03WithEvnEarlierThanA01A04'
							selected={settings?.processA03WithEvnEarlierThanA01A04}
						/>
					</FormEntry>
					<FormEntry required label={'isolationParseMethod'}>
						<Input
							containerClassName='sm:w-full md:w-full lg:w-full xl:w-2/3'
							placeholder={'isolationParseMethod'}
							type='number'
							min={0}
							required={true}
							name='isolationParseMethod'
							defaultValue={settings?.isolationParseMethod}
						/>
					</FormEntry>
					{permissions.Update && (
						<tr>
							<td colSpan={2}>
								<div className='text-right p-10 pb-5'>
									<Button type='submit' isLoading={isLoading}>
										<i className='ri-save-line mr-2'></i>
										{translate('saveChanges')}
									</Button>
								</div>
							</td>
						</tr>
					)}
				</Form>
			</div>
		</Card>
	);
};
export default HL7SettingsView;
