import Card from 'components/ui/Card';
import { Table } from 'components/ui/Table';
import { CrudOperations } from 'hoc';
import { useCrud, useTranslations } from 'hooks';
import { useEffect, useState } from 'react';
import TaskTypeForm from '../forms/taskType.form';
import TaskTypeModel from '../models';
import TaskTypeService from '../services';

const Tables = ({ taskGroupId, permissions }) => {
	const [data, setData] = useState({ data: [], meta: {} });
	const [queryString, setQueryString] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const service = new TaskTypeService();
	const model = new TaskTypeModel();
	service.setParent(taskGroupId);

	const { getAll, getExport, create, update, remove } = useCrud(service);
	const { translate } = useTranslations();

	const fetchAndLoad = async (l_queryString = null) => {
		setIsLoading(true);
		const res = await getAll(l_queryString || queryString);
		setIsLoading(false);
		setData(res);
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: TaskTypeForm,
		componentName: model.getModelName(),
		permissions: permissions,
	});

	useEffect(() => {
		fetchAndLoad();
	}, [taskGroupId]);

	return (
		<Card
			className='mt-2'
			collapsible
			defaultOpen={true}
			header={model.getPluralModelName()}
			headerButtonLabel={permissions.Create && `+ ${translate('createTaskType')}`}
			headerButtonClick={openCreate}
		>
			<Table
				isLoading={isLoading}
				model={model}
				isExportable={permissions.Export}
				onExportClick={async (queryString) => {
					return await getExport(queryString, model.getPluralModelName());
				}}
				onRowClick={(row) => permissions.Update && openEdit(row)}
				data={data?.data}
				meta={data.meta}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
			/>
		</Card>
	);
};
export default Tables;
