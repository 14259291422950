import { useState, useEffect, useMemo } from "react";
import Swal from "sweetalert2";

import { withSuspense } from "hoc";
import { useAside, useToolbar, useTranslations } from "hooks";

import { SegmentedControl } from "components/ui/Interactive";

import TreeView from "./parts/TreeView";
import TableView from "./parts/TableView";
import usePermissions, { RESOURCE, PERMISSION } from "hooks/usePermissions";
import ImportForm from "./forms/import.form";
import { themeConfig } from "utils";
import { postImport } from "modules/addresses/services/addresses";

const VIEWS = {
	TREE: 0,
	TABLE: 1,
};

const AddressesPage = () => {
	const { translate } = useTranslations();
	const { crumbsBuilder, actionsBuilder } = useToolbar();
	const { hasPermission } = usePermissions();
	const { asideBuilder } = useAside();

	const permissionsMap = useMemo(
		() => ({
			Create: hasPermission(RESOURCE.Address, PERMISSION.Create),
			View: hasPermission(RESOURCE.Address, PERMISSION.View),
			Update: hasPermission(RESOURCE.Address, PERMISSION.Create),
			Delete: hasPermission(RESOURCE.Address, PERMISSION.Delete),
			Import: hasPermission(RESOURCE.Address, PERMISSION.Import),
			Export: hasPermission(RESOURCE.Address, PERMISSION.Export),
		}),
		[hasPermission]
	);

	const [selectedSegment, setSelectedSegment] = useState(0);

	const handleImport = (file) => {
		console.log("Import should start now");
		Swal.fire({
			title: "You are about to import addresses",
			showCancelButton: true,
			confirmButtonText: "Confirm",
			icon: "warning",
			showLoaderOnConfirm: true,
			cancelButtonText: translate("cancel"),
			confirmButtonColor: themeConfig.color.primary,
			cancelButtonColor: "#b5bec9",
			preConfirm: async () => {
				asideBuilder.setOpen(false);
				asideBuilder.build();

				try {
					const res = await postImport(file);
					console.log("Response from file upload", res);
					return {
						success: res?.succeeded,
						message:
							res?.message === "ActionFailed"
								? null
								: res.message,
					};
				} catch (e) {
					console.log("Error in import", e);
					return { success: false, message: null };
				}
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result) => {
			if (result.value?.success) {
				Swal.fire({
					icon: "success",
					title: `Successfully imported addresses`,
					confirmButtonColor: themeConfig.color.primary,
					cancelButtonColor: "#b5bec9",
				}).then(() => window.location.reload());
			} else {
				Swal.fire({
					icon: "error",
					title: `There was a problem importing the file.`,
					html: `
							<div>
								<p>${
									result.value?.message ||
									"Please check the file and use the provided import template"
								}<p>
							</div>
						`,
					confirmButtonColor: themeConfig.color.primary,
				});
			}
		});
	};

	const openImport = () => {
		asideBuilder.setTitle("New Import");
		asideBuilder.setComponent(ImportForm);
		asideBuilder.setComponentKey(`new-import-${new Date()}`);
		asideBuilder.hideSaveButton(true);
		asideBuilder.setComponentProps({
			onImportStart: handleImport,
		});
		asideBuilder.setOpen(true);
		asideBuilder.build();
	};

	useEffect(() => {
		crumbsBuilder.addCurrent("locations");
		crumbsBuilder.build();

		console.log("My permissions", !permissionsMap.Import);
		if (permissionsMap.Import) {
			const importAction = actionsBuilder.newAction(
				"Import",
				"ri-contacts-book-upload-line"
			);
			importAction.callback = openImport;
			actionsBuilder.addAction(importAction);
			actionsBuilder.build();
		}

		return () => {
			actionsBuilder.reset();
		};
	}, [permissionsMap]);

	return (
		permissionsMap.View && (
			<>
				<div className="w-full h-full flex flex-col">
					<div className="flex flex-row mb-4 justify-between">
						<div className="flex"></div>
						<SegmentedControl
							withIcons={true}
							segments={["ri-node-tree", "ri-table-line"]}
							onSegmentChange={(index) =>
								setSelectedSegment(index)
							}
						/>
					</div>

					<div className="grid grid-cols-1 gap-2 w-full">
						{selectedSegment === VIEWS.TREE ? (
							<TreeView permissions={permissionsMap} />
						) : (
							<TableView permissions={permissionsMap} />
						)}
					</div>
				</div>
			</>
		)
	);
};

export default withSuspense(AddressesPage);
