import React from "react";
import { Navigate } from "react-router-dom";

import NotImplemented from "components/system/NotImplemented";

import Tasks from "../pages/Tasks";
import Manage from "../pages/Manage";
import Settings from "../pages/Settings";
import Rules from "../pages/Rules";

const routes = [
	{ index: true, element: <Navigate to="tasks" replace /> },
	{ path: "tasks/*", element: <Tasks /> },
	{ path: "rules/*", element: <Rules /> },
	{ path: "manage/*", element: <Manage /> },
	{ path: "settings/*", element: <Settings /> },

	{ path: "*", element: <Navigate to={""} replace /> },
	// { index: true, element: <Navigate to={"/media"} replace /> },
];

export default routes;
