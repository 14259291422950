import { useState, useCallback, useMemo } from "react";
import ToolbarContext from "./ToolbarContext";

import Breadcrumbs from "components/system/Breadcrumbs";
import { Button } from "components/ui/Input";

const ToolbarProvider = ({ children }) => {
	const [crumbs, setCrumbs] = useState([]);
	const [tools, setTools] = useState([]);

	const providerValue = useMemo(
		() => ({
			crumbs,
			setCrumbs,
			tools,
			setTools,
		}),
		[crumbs, tools]
	);

	return (
		<ToolbarContext.Provider value={providerValue}>
			<nav
				style={{ height: 42 }}
				className="bg-white border-gray-200 border-b"
			>
				<div className="w-full flex flex-row items-center justify-between mx-auto px-3 py-1">
					<Breadcrumbs items={crumbs} />
					<div className="text-xs px-3 py-2">&nbsp;</div>
					{tools && (
						<div className={"flex-row gap-5"}>
							{tools?.map((tool, index) => {
								if (tool.type === "link") {
									return (
										<div
											key={`tool-${index}`}
											onClick={tool.callback}
											className="cursor-pointer text-sm opacity-50 hover:opacity-75 px-3 flex-row gap-5"
										>
											{tool.icon && (
												<>
													<i
														className={tool.icon}
													></i>
													&nbsp;&nbsp;
												</>
											)}
											{tool.name}
										</div>
									);
								} else
									return (
										<Button small onClick={tool.callback}>
											{tool.name}
										</Button>
									);
							})}
						</div>
					)}
				</div>
			</nav>
			{children}
		</ToolbarContext.Provider>
	);
};

export default ToolbarProvider;
