import React, { useState, useRef, useEffect } from "react";
import { useTranslations } from "hooks";

function MultiDateInput({
	name,
	required,
	defaultValue,
	onChange = () => {},
	containerClassName,
}) {
	const [dates, setDates] = useState([]);
	const inputRef = useRef(null);

	const [editMode, setEditMode] = useState(false);
	const [editIndex, setEditIndex] = useState(null);

	const { translate } = useTranslations();

	useEffect(() => {
		if (defaultValue) {
			if (typeof defaultValue === "string") {
				setDates(JSON.parse(defaultValue));
			} else {
				setDates(defaultValue);
			}
		}
	}, [defaultValue]);

	const submitDate = () => {
		const date = inputRef.current.value;
		if (!date) return;

		if (editMode) {
			const newDates = [...dates];
			newDates[editIndex] = date;
			setDates(newDates);
			onChange(newDates);
			setEditMode(false);
			setEditIndex(false);
			inputRef.current.value = "";
		} else {
			const l_dates = [...dates, date];
			setDates(l_dates);
			inputRef.current.value = "";
			onChange(l_dates);
		}
	};

	const cancelEdit = () => {
		setEditMode(false);
		setEditIndex(null);
		inputRef.current.value = "";
	};

	const editDate = (index, date) => {
		inputRef.current.value = date;
		setEditMode(true);
		setEditIndex(index);
	};

	const deleteDate = (index) => {
		const newDates = [...dates];
		newDates.splice(index, 1);
		setDates(newDates);
		onChange(newDates);
	};

	return (
		<div
			className={`relative bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 my-1 cursor-pointer ${containerClassName}`}
		>
			<input
				type="text"
				name={name}
				required={required}
				className="sr-only"
				value={dates?.length > 0 ? JSON.stringify(dates) : ""}
				onChange={() => {}}
			/>
			{dates.map((option, index) => (
				<div
					key={index}
					title={translate("editThisDate")}
					className="flex justify-between items-center mb-2 border-b border-gray-200 pb-1 px-1 opacity-60 hover:opacity-100"
				>
					<span
						className="cursor-pointer"
						onClick={() => {
							editDate(index, option);
						}}
					>
						{option}
					</span>
					<div
						onClick={(e) => {
							e.stopPropagation();
							deleteDate(index);
						}}
					>
						<i className="ri-delete-bin-line text-md "></i>
					</div>
				</div>
			))}

			<div className="relative">
				<input
					type="date"
					ref={inputRef}
					placeholder={
						editMode
							? `${translate("editThisDate")}...`
							: `${translate("newDate")}...`
					}
					className="mr-2 pt-2 rounded-md border border-gray-300 w-full p-1.5 pl-3 pr-10"
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							e.preventDefault();
							submitDate();
						}
					}}
				/>
				<button
					title={
						editMode
							? translate("editThisDate")
							: translate("addDate")
					}
					type="button"
					onClick={() => {
						submitDate();
					}}
					className="absolute inset-y-0 right-0 pr-3 flex items-center"
				>
					{editMode ? (
						<div className="flex space-x-2">
							<i className=" ri-check-line text-xl opacity-40 hover:opacity-100"></i>
							<i
								onClick={(e) => {
									e.stopPropagation();
									cancelEdit();
								}}
								className=" ri-close-line text-xl opacity-40 hover:opacity-100"
							></i>
						</div>
					) : (
						<i className=" ri-add-circle-line text-lg opacity-40 hover:opacity-100"></i>
					)}
				</button>
			</div>
		</div>
	);
}

export default MultiDateInput;
