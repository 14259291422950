import { useContext, useRef, useEffect } from "react";
import { ToolbarContext } from "contexts/ToolbarManager";
import { useLocation } from "react-router-dom";

const initialState = {
	breadcrumbs: [],
	tools: [],
};

const useToolbar = () => {
	const { setCrumbs, setTools } = useContext(ToolbarContext);
	const config = useRef(initialState);

	const crumbsBuild = () => {
		setCrumbs(config.current.breadcrumbs);
		config.current.breadcrumbs = [];
	};

	const actionsBuild = (reset = false) => {
		setTools(reset ? [] : config.current.tools);
		config.current.tools = [];
	};

	const crumbsBuilder = {
		addPath: (to, label) => {
			config.current.breadcrumbs.push({
				to,
				label,
			});
		},
		addCurrent: (label) => {
			config.current.breadcrumbs.push({
				label,
				current: true,
			});
		},
		addComponent: (component) => {
			config.current.breadcrumbs.push({
				label: "custom",
				component,
			});
		},
		build: crumbsBuild,
	};

	const actionsBuilder = {
		newAction: (
			name = "Action",
			icon = null,
			type = "link",
			callback = () => {}
		) => {
			return { name, icon, callback, type };
		},
		addAction: (action) => {
			config.current.tools.push(action);
		},
		build: actionsBuild,
		reset: () => actionsBuild(true),
	};

	return {
		crumbsBuilder,
		actionsBuilder,
	};
};

export default useToolbar;
