import configMap from "configmap.json";

const tenant = {
	id:
		process.env.NODE_ENV === "development"
			? "develop2"
			: configMap.tenant || window.location.host?.split(".")?.[0],
};

export default tenant;
