import api from "services/api";
import tokenService from "services/token/token.service";

const endpoint = "users";

export const updateSettings = async (data) => {
	return await api.put(`${endpoint}/me/settings`, data);
};

export const changePassword = async (
	password,
	currentPassword,
	fromOnboarding = false
) => {
	if (fromOnboarding) {
		const settings = tokenService.getSettings();
		currentPassword = atob(settings.secret);
	}
	return await api.put(`${endpoint}/me/reset-password`, {
		password,
		confirmPassword: password,
		currentPassword,
	});
};

export const get2Fa = async (emailOtp = false) => {
	const query = emailOtp ? "?type=3" : "?type=1";
	return await api.get(`${endpoint}/me/two-factor-auth${query}`);
};

export const activate2Fa = async (code, isEmail) => {
	return await api.put(`${endpoint}/me/two-factor-auth`, {
		code,
		twoFaType: isEmail ? 3 : 1, // TODO: Change to ENUMs
	});
};

export const loadUserPermissions = async () => {
	return await api.get(`${endpoint}/me/permissions`);
};
