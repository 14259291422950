import Card from 'components/ui/Card';
import { Button } from 'components/ui/Input';
import { Table } from 'components/ui/Table';
import { CrudOperations } from 'hoc';
import { useCrud, useTranslations } from 'hooks';
import { useState } from 'react';
import toast from 'react-hot-toast';
import AdConfigurationForm from '../forms/AdConfiguration.form';
import ADConfigModel from '../models';
import ADConfigService from '../services';

const Tables = ({ permissions }) => {
	const service = new ADConfigService();
	const model = new ADConfigModel();

	const { create, update, getAll, remove } = useCrud(service);
	const { translate } = useTranslations();
	const [data, setData] = useState({ data: [], meta: {} });
	const [syncing, setSyncing] = useState(false);
	const [queryString, setQueryString] = useState('');

	const fetchAndLoad = async (l_queryString = null) => {
		try {
			const res = await getAll(l_queryString || queryString);
			setData(res);
		} catch (error) {
			console.error('Error on Get All request: ', error);
		}
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: AdConfigurationForm,
		componentName: model.getModelName(),
		permissions: permissions,
	});

	const sync = async () => {
		setSyncing(true);
		const res = await service.synchronize();
		setSyncing(false);
		if (res.succeeded) {
			toast(translate('synchronizationSuccessful'), {
				duration: 2000,
				icon: '✅',
			});
		} else {
			console.error(res.message);
			toast(translate('synchronizationFailed'), {
				duration: 2000,
				icon: '❗️',
			});
		}
	};

	const SecondaryComponent = () => (
		<Button
			disabled={syncing}
			onClick={(e) => {
				sync();
				e.stopPropagation();
			}}
		>
			{syncing ? (
				<div className='flex flex-row space-x-2'>
					<div className='animate-spin'>
						<i className='ri-loader-4-line'></i>
					</div>
					<div>{translate('synchronizing')}...</div>
				</div>
			) : (
				<>
					<i className='ri-loop-right-line mr-2'></i> {translate('syncOnDemand')}
				</>
			)}
		</Button>
	);

	return (
		<Card
			className='mt-2'
			collapsible
			header={'activeDirectoryConfiguration'}
			defaultOpen={true}
			headerButtonClick={openCreate}
			headerButtonLabel={permissions.Create && `+ ${translate('newConfiguration')}`}
			SecondaryComponent={permissions.Sync && SecondaryComponent}
		>
			<Table
				model={model}
				meta={data.meta}
				onTableRequestChange={(queryString) => {
					setQueryString(queryString);
					fetchAndLoad(queryString);
				}}
				onRowClick={(row) => permissions.Update && openEdit(row)}
				data={data.data.map((item) => {
					return {
						...item,
						userProfilesList: item.userProfiles?.map((userProfile) => userProfile.name).join(', '),
					};
				})}
			/>
		</Card>
	);
};

export default Tables;
