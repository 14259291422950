import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
} from "react";
import { Loading } from "components/ui/Interactive";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import { useTranslations, useCrud } from "hooks";
import {
	CheckBox,
	Input,
	MultipleSelect,
	ReSelect,
	TextArea,
	MultiAddressSelector,
} from "components/ui/Input";

import TaskGroupService from "modules/tasks/pages/Manage/services";
import TaskTypeService from "modules/tasks/pages/Manage/pages/TaskTypes/services";
import { getToday, getCurrentTime } from "utils";

const AdjustStaRuleForm = forwardRef((props, ref) => {
	const { translate } = useTranslations();

	const today = getToday();
	const currentTime = getCurrentTime();

	const { getOne } = useCrud(props.service);

	const [data, setData] = useState([]);
	const myForm = useRef(null);

	const [loading, setLoading] = useState(true);

	const [taskTypesOptions, setTaskTypesOptions] = useState([]);

	const taskGroupService = new TaskGroupService();
	const taskTypeService = new TaskTypeService();

	const fetchTaskTypes = (taskGroupId = -1) => {
		taskTypeService.setParent(taskGroupId);
		taskTypeService.getOptionsList().then((res) => {
			setTaskTypesOptions(res?.data);
		});
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				const l_taskGroupId = res?.adjustStaRule?.taskGroup?.id;
				setData(res);
				setLoading(false);

				fetchTaskTypes(l_taskGroupId);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	const dayOfWeekOptions = [
		{ value: 0, label: translate("sunday") },
		{ value: 1, label: translate("monday") },
		{ value: 2, label: translate("tuesday") },
		{ value: 3, label: translate("wednesday") },
		{ value: 4, label: translate("thursday") },
		{ value: 5, label: translate("friday") },
		{ value: 6, label: translate("saturday") },
	];

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className="w-full h-100 pb-10 overflow-y-visible">
				<Form ref={myForm}>
					<input type="hidden" name="id" value={data?.id || false} />
					<input
						type="hidden"
						name="type"
						value={props.service.getTypeId()}
					/>
					<FormEntry label="name" required={true}>
						<Input
							type="text"
							placeholder={"name"}
							required={true}
							name="name"
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label="description">
						<TextArea
							placeholder={"description"}
							name="description"
							defaultValue={data?.description}
						/>
					</FormEntry>
					<FormEntry label={"enabled"}>
						<CheckBox
							name="enabled"
							label="enabled"
							selected={data?.enabled}
						/>
					</FormEntry>
					<InternalFormDivider>
						{translate("configuration")}
					</InternalFormDivider>
					<FormEntry label="startDate">
						<Input
							type="date"
							placeholder={"startDate"}
							name="adjustStaRule.startDate"
							defaultValue={
								data?.adjustStaRule?.startDate || today
							}
						/>
					</FormEntry>
					<FormEntry label="endDate">
						<Input
							type="date"
							placeholder={"endDate"}
							name="adjustStaRule.endDate"
							defaultValue={data?.adjustStaRule?.endDate || today}
						/>
					</FormEntry>
					<FormEntry label="startTime">
						<Input
							type="time"
							placeholder={"startTime"}
							name="adjustStaRule.startTime"
							defaultValue={
								data?.adjustStaRule?.startTime || currentTime
							}
						/>
					</FormEntry>
					<FormEntry label="endTime">
						<Input
							type="time"
							placeholder={"endTime"}
							name="adjustStaRule.endTime"
							defaultValue={
								data?.adjustStaRule?.endTime || currentTime
							}
						/>
					</FormEntry>
					<FormEntry label="validDaysOfWeek">
						<MultipleSelect
							noAllAtSubmit={true}
							hasAllOption={false}
							name="adjustStaRule.validDaysOfWeekArray"
							options={dayOfWeekOptions}
							defaultValue={data?.adjustStaRule?.validDaysOfWeek}
						/>
					</FormEntry>
					<FormEntry label="taskGroup">
						<ReSelect
							extraParams="HideAllItem=true"
							name="adjustStaRule.taskGroupId"
							defaultValue={data?.adjustStaRule?.taskGroup}
							service={taskGroupService}
							onChange={(selected) => {
								fetchTaskTypes(selected);
							}}
						/>
					</FormEntry>
					<FormEntry label="taskTypes">
						<MultipleSelect
							noAllAtSubmit={true}
							hasAllOption={false}
							name="adjustStaRule.taskTypeIdsArray"
							options={taskTypesOptions}
							defaultValue={data?.adjustStaRule?.taskTypeIds}
						/>
					</FormEntry>

					<FormEntry label="fromLocation">
						<MultiAddressSelector
							name="adjustStaRule.from"
							addresses={data?.adjustStaRule?.from}
						/>
					</FormEntry>
					<FormEntry label="toLocation">
						<MultiAddressSelector
							name="adjustStaRule.to"
							addresses={data?.adjustStaRule?.to}
						/>
					</FormEntry>

					<FormEntry label="newPlanTime">
						<Input
							type="time"
							placeholder={"newPlanTime"}
							name="adjustStaRule.newPlanTime"
							defaultValue={
								data?.adjustStaRule?.newPlanTime || currentTime
							}
						/>
					</FormEntry>
					<FormEntry label={"additionalMinutesToPlanTime"}>
						<Input
							placeholder={"additionalMinutesToPlanTime"}
							type="number"
							min={0}
							name="adjustStaRule.additionalMinutesToPlanTime"
							defaultValue={
								data?.adjustStaRule?.additionalMinutesToPlanTime
							}
							sideLabel={"mins"}
						/>
					</FormEntry>
					<FormEntry label={"additionalDaysToPlanTime"}>
						<Input
							placeholder={"additionalDaysToPlanTime"}
							type="number"
							min={0}
							name="adjustStaRule.additionalDaysToPlanTime"
							defaultValue={
								data?.adjustStaRule?.additionalDaysToPlanTime
							}
							sideLabel={"days"}
						/>
					</FormEntry>

					<FormEntry label="newTaskType">
						<ReSelect
							name="adjustStaRule.newTaskTypeId"
							defaultValue={data?.adjustStaRule?.newTaskType}
							options={taskTypesOptions}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default AdjustStaRuleForm;
