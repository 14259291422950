import { useState, useRef } from "react";
import { useTranslations } from "hooks";
import { Input } from "components/ui/Input";

const HeaderModifier = ({
	name,
	onClick = () => {},
	onNameChanged = () => {},
}) => {
	const ref = useRef();

	const handleNameChange = () => {
		const value = ref.current.value;
		if (value.length > 0) {
			onNameChanged(ref.current.value);
		}
		onClick(false);
	};

	return (
		<div className="w-1/2">
			<Input
				type={"text"}
				ref={ref}
				placeholder={name}
				className="pt-1 pb-1"
				onKeyPress={(event) => {
					if (event.key === "Enter") {
						handleNameChange();
					}
				}}
				sideLabel={
					<div className="text-xl space-x-2 pl-2">
						<i
							className="ri-check-line cursor-pointer"
							onClick={() => {
								handleNameChange();
							}}
						></i>
						<i
							className="ri-close-line cursor-pointer"
							onClick={() => onClick(false)}
						></i>
					</div>
				}
			/>
		</div>
	);
};

const HeaderInput = ({
	name,
	isDisabled = false,
	onNameChanged = () => {},
}) => {
	const { translate } = useTranslations();
	const [isEditing, setIsEditing] = useState(false);

	return (
		<tr>
			<td colSpan={2}>
				<div className="pt-2 pb-2  ">
					{isEditing ? (
						<HeaderModifier
							name={translate(name, true)}
							onClick={(value) => setIsEditing(value)}
							onNameChanged={onNameChanged}
						/>
					) : (
						<div className=" flex flex-row items-center">
							<div className="font-medium text-md">
								{translate(name, true)}
							</div>
							{!isDisabled && (
								<div
									className=" pl-2"
									onClick={() => {
										setIsEditing(true);
									}}
								>
									<i className="ri-pencil-line opacity-40 group-hover:opacity-100 cursor-pointer"></i>
								</div>
							)}
						</div>
					)}
				</div>
			</td>
		</tr>
	);
};

export default HeaderInput;
