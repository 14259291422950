import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
} from "react";
import { Loading } from "components/ui/Interactive";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import { useAside, useTranslations, useCrud } from "hooks";
import {
	CheckBox,
	Input,
	MultipleSelect,
	ReSelect,
	TextArea,
} from "components/ui/Input";

import TaskGroupService from "modules/tasks/pages/Manage/services";
import TaskTypeService from "modules/tasks/pages/Manage/pages/TaskTypes/services";
import RoomTypeService from "modules/addresses/pages/settings/pages/RoomType/services";

const PriorityRuleForm = forwardRef((props, ref) => {
	const { translate } = useTranslations();

	const { helperAsideBuilder } = useAside();

	const { getOne } = useCrud(props.service);

	const [data, setData] = useState([]);
	const myForm = useRef(null);

	const [loading, setLoading] = useState(true);

	const [taskTypesOptions, setTaskTypesOptions] = useState([]);
	const [roomTypeOptions, setRoomTypeOptions] = useState([]);

	const taskGroupService = new TaskGroupService();
	const taskTypeService = new TaskTypeService();
	const roomTypeService = new RoomTypeService();

	const fetchTaskTypes = (taskGroupId = -1) => {
		taskTypeService.setParent(taskGroupId);
		taskTypeService.getOptionsList().then((res) => {
			setTaskTypesOptions(res?.data);
		});
	};

	const fetchRoomTypes = () => {
		roomTypeService.getOptionsList().then((res) => {
			setRoomTypeOptions(res?.data);
		});
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);

				fetchTaskTypes(res?.monitorDoubleTaskRule?.taskGroup?.id);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchRoomTypes();
		initiateData();
	}, []);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className="w-full h-100 pb-10 overflow-y-visible">
				<Form ref={myForm}>
					<input type="hidden" name="id" value={data?.id || false} />
					<input
						type="hidden"
						name="type"
						value={props.service.getTypeId()}
					/>
					<FormEntry label="name" required={true}>
						<Input
							type="text"
							placeholder={"name"}
							required={true}
							name="name"
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label="description">
						<TextArea
							placeholder={"description"}
							name="description"
							defaultValue={data?.description}
						/>
					</FormEntry>
					<FormEntry label={"enabled"}>
						<CheckBox
							name="enabled"
							label="enabled"
							selected={data?.enabled}
						/>
					</FormEntry>
					<InternalFormDivider>
						{translate("configuration")}
					</InternalFormDivider>

					<FormEntry label="taskGroup">
						<ReSelect
							extraParams="HideAllItem=true"
							name="monitorDoubleTaskRule.taskGroupId"
							defaultValue={
								data?.monitorDoubleTaskRule?.taskGroup
							}
							service={taskGroupService}
							onChange={(selected) => {
								fetchTaskTypes(selected);
							}}
						/>
					</FormEntry>

					<FormEntry label="taskTypes">
						<MultipleSelect
							noAllAtSubmit={true}
							hasAllOption={false}
							name="monitorDoubleTaskRule.taskTypeIdsArray"
							options={taskTypesOptions}
							defaultValue={
								data?.monitorDoubleTaskRule?.taskTypeIds
							}
						/>
					</FormEntry>
					<FormEntry label="fromRoomTypes">
						<MultipleSelect
							noAllAtSubmit={true}
							hasAllOption={true}
							name="monitorDoubleTaskRule.fromRoomTypeIdsArray"
							options={roomTypeOptions}
							value={data?.monitorDoubleTaskRule?.fromRoomTypeIds}
						/>
					</FormEntry>
					<FormEntry label="toRoomTypes">
						<MultipleSelect
							noAllAtSubmit={true}
							hasAllOption={true}
							name="monitorDoubleTaskRule.toRoomTypeIdsArray"
							options={roomTypeOptions}
							value={data?.monitorDoubleTaskRule?.toRoomTypeIds}
						/>
					</FormEntry>
					<FormEntry label={"filterTaskBarcode"}>
						<CheckBox
							name="monitorDoubleTaskRule.filterTaskBarcode"
							label="filterTaskBarcode"
							selected={
								data?.monitorDoubleTaskRule?.filterTaskBarcode
							}
						/>
					</FormEntry>
					<FormEntry label="timeMargin">
						<Input
							type="number"
							min={0}
							placeholder={"timeMargin"}
							name="monitorDoubleTaskRule.timeMargin"
							defaultValue={
								data?.monitorDoubleTaskRule?.timeMargin
							}
						/>
					</FormEntry>
					<FormEntry label={"matchFrom"}>
						<CheckBox
							name="monitorDoubleTaskRule.matchFrom"
							label="matchFrom"
							selected={data?.monitorDoubleTaskRule?.matchFrom}
						/>
					</FormEntry>
					<FormEntry label={"matchTo"}>
						<CheckBox
							name="monitorDoubleTaskRule.matchTo"
							label="matchTo"
							selected={data?.monitorDoubleTaskRule?.matchTo}
						/>
					</FormEntry>
					<FormEntry label={"matchTaskType"}>
						<CheckBox
							name="monitorDoubleTaskRule.matchTaskType"
							label="matchTaskType"
							selected={
								data?.monitorDoubleTaskRule?.matchTaskType
							}
						/>
					</FormEntry>
					<FormEntry label={"matchTaskName"}>
						<CheckBox
							name="monitorDoubleTaskRule.matchTaskName"
							label="matchTaskName"
							selected={
								data?.monitorDoubleTaskRule?.matchTaskName
							}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default PriorityRuleForm;
