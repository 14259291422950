import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslations } from "hooks";

const ICONS = {
	default: "ri-checkbox-blank-line text-gray-500",
	active: "ri-checkbox-fill",
	indeterminate: "ri-checkbox-indeterminate-line",
};

const CheckBox = ({
	label,
	className = "",
	selected = null,
	disabled = false,
	onChange = () => {},
	labelOnNewLine = false,
	checkboxSize = "text-2xl",
	name = "",
	checkedValue = "true",
	uncheckedValue = "false",
	labelClass = "",
	title = null,
	defaultValue = null,
	halfChecked = false,
}) => {
	const { translate } = useTranslations();
	const [selectedState, setSelectedState] = useState(selected ?? false);

	useEffect(() => {
		setSelectedState(selected);
	}, [selected]);

	useEffect(() => {
		if (defaultValue !== null) setSelectedState(defaultValue);
	}, [defaultValue]);

	const handleDivClick = (e) => {
		const checkbox = e.currentTarget.querySelector(
			'input[type="checkbox"]'
		);
		checkbox.checked = !selectedState;
		const newEvent = {
			target: checkbox,
			currentTarget: checkbox,
		};
		onChange(newEvent);
		setSelectedState(!selectedState);
	};

	return (
		<div
			onClick={!disabled ? handleDivClick : null}
			className={`${
				disabled ? "cursor-not-allowed" : "cursor-pointer"
			} flex ${
				labelOnNewLine
					? "flex-col items-center"
					: "flex-row items-center"
			} p-1 ${className}`}
		>
			<i
				className={`${checkboxSize} ${
					selectedState
						? ICONS.active
						: halfChecked
						? ICONS.indeterminate
						: ICONS.default
				} ${disabled && "text-gray-300"} ${
					!selectedState &&
					halfChecked &&
					"text-gray-400 hover:text-gray-600"
				} `}
			/>
			<div
				className={`text-sm ${
					labelOnNewLine ? "-mt-1" : "ml-2"
				} ${labelClass} ${disabled && "opacity-50"}`}
			>
				{typeof label === "string" ? translate(label, true) : label}
			</div>
			<input
				type="hidden"
				disabled={disabled}
				value={uncheckedValue}
				name={name}
			/>
			<input
				type="checkbox"
				disabled={disabled}
				style={{ display: "none" }}
				value={checkedValue}
				name={name}
				checked={selectedState}
				onChange={() => {}}
			/>
		</div>
	);
};

CheckBox.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	onChange: PropTypes.func,
	selected: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
};

export default CheckBox;
