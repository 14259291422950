import React, { useState, useEffect, useRef } from "react";
import Card from "components/ui/Card";
import TenantUtils from "../../utils";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import { useTranslations } from "hooks";

import { useCrud } from "hooks";
import {
	CheckBox,
	Input,
	TextArea,
	Select,
	ReSelect,
	Button,
} from "components/ui/Input";
import TenantService from "../../services";

const ReservedSettingsView = ({ permissions }) => {
	const [settings, setSettings] = useState(null);

	const myForm = useRef(null);
	const { translate } = useTranslations();
	const service = new TenantService();

	const { create, getOne } = useCrud({
		create: (data) => service.putReservedSettings(data),
		getOne: (id) => service.getReservedSettings(),
	});
	const [isLoading, setIsLoading] = useState(false);

	//handle the form submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(myForm.current);
		const formDataObject = Object.fromEntries(formData.entries());
		setIsLoading(true);
		await postSettings(formDataObject, (res) => {
			setIsLoading(false);
			getSettings(false);
		});
	};

	const postSettings = async (data, onSuccess = () => {}) => {
		if (data) {
			return await create(data, {
				creationSuccessMessage:
					"tenantReservedSettingsUpdatedSuccessfully",
			}).then((res) => {
				onSuccess(res);
			});
		}
		return null;
	};

	useEffect(() => {
		getSettings();
	}, []);

	const getSettings = async () => {
		setIsLoading(true);

		await getOne().then((res) => {
			setIsLoading(false);
			setSettings(res);
		});
	};
	const addressLevelOptions = [
		{ value: 6, label: "groups" },
		{ value: 5, label: "campuses" },
		{ value: 4, label: "sites" },
		{ value: 3, label: "zones" },
		{ value: 2, label: "rooms" },
		{ value: 1, label: "beds" },
	];

	return (
		<Card
			isLoading={isLoading}
			className="mt-2"
			collapsible
			header={"reservedSettings"}
			defaultOpen={true}
		>
			<div>
				<Form ref={myForm} onSubmit={(e) => handleSubmit(e)}>
					<InternalFormDivider>
						{translate("generalSettings")}
					</InternalFormDivider>

					<FormEntry required label={"frontendUrl"}>
						<Input
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							placeholder={"frontendUrl"}
							required={true}
							type={"url"}
							name="frontendUrl"
							defaultValue={settings?.frontendUrl || ""}
						/>
					</FormEntry>

					<InternalFormDivider>
						{translate("addressSettings")}
					</InternalFormDivider>
					<FormEntry required label="baseAddressLevel">
						<ReSelect
							required
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							name="baseAddressLevel"
							options={addressLevelOptions}
							defaultValue={settings?.baseAddressLevel || null}
						/>
					</FormEntry>

					<FormEntry required label={"rootAddressLevel"}>
						<ReSelect
							required
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							name="rootAddressLevel"
							options={addressLevelOptions}
							defaultValue={settings?.rootAddressLevel || null}
						/>
					</FormEntry>

					<InternalFormDivider>
						{translate("configuration")}
					</InternalFormDivider>
					<FormEntry label={"firebaseConfig"}>
						<TextArea
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							maxLength={5000}
							rows={4}
							placeholder={"firebaseConfig"}
							required={false}
							name="firebaseConfig"
							defaultValue={settings?.firebaseConfig || ""}
						/>
					</FormEntry>
					<FormEntry required label={"minLoggingLevel"}>
						<Input
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							placeholder={"minLoggingLevel"}
							type="number"
							min={1}
							required={true}
							name="minLoggingLevel"
							defaultValue={settings?.minLoggingLevel || ""}
						/>
					</FormEntry>
					<FormEntry label={"logRequestResponse"}>
						<CheckBox
							name="logRequestResponse"
							label="logRequestResponse"
							selected={settings?.logRequestResponse || false}
						/>
					</FormEntry>

					<FormEntry required label={"maxTaskGroups"}>
						<Input
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							placeholder={"maxTaskGroups"}
							type="number"
							min={1}
							required={true}
							name="maxTaskGroups"
							defaultValue={settings?.maxTaskGroups || ""}
						/>
					</FormEntry>

					<InternalFormDivider>
						{translate("office365Settings")}
					</InternalFormDivider>
					<FormEntry required label={"port"}>
						<Input
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							placeholder={"port"}
							type="number"
							min={1}
							required={true}
							name="office365.port"
							defaultValue={settings?.office365?.port || ""}
						/>
					</FormEntry>
					<FormEntry required label={"email"}>
						<Input
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							placeholder={"email"}
							type="email"
							min={1}
							required={true}
							name="office365.email"
							defaultValue={settings?.office365?.email || ""}
						/>
					</FormEntry>
					<FormEntry required label={"name"}>
						<Input
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3 "
							placeholder={"name"}
							type="text"
							min={1}
							required={true}
							name="office365.name"
							defaultValue={settings?.office365?.name || ""}
						/>
					</FormEntry>
					<FormEntry required={true} label={"password"}>
						<Input
							containerClassName="sm:w-full md:w-full lg:w-full xl:w-2/3"
							placeholder={"password"}
							type="password"
							min={1}
							required={true}
							name="office365.password"
							defaultValue={settings?.office365?.password || ""}
						/>
					</FormEntry>

					{permissions.Update && (
						<tr>
							<td colSpan={2}>
								<div className="text-right p-10 pb-5">
									<Button type="submit" isLoading={isLoading}>
										<i className="ri-save-line mr-2"></i>
										{translate("saveChanges")}
									</Button>
								</div>
							</td>
						</tr>
					)}
				</Form>
			</div>
		</Card>
	);
};

export default ReservedSettingsView;
