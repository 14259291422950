import { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { buildExportParams, buildFilterParams, buildPagination, sanitizeFilters } from 'utils/pagination';

import useDebounce from 'hooks/useDebounce';
import Pagination from './parts/Pagination';
import Table from './table';

import TableColumns from './ColumnPicker';

import StorageHandler from 'core/utils/StorageHandler';
import { useTranslations } from 'hooks';

const TableWrapper = ({
	data,
	meta,
	controls = <></>,
	onRowClick = () => {},
	onTableRequestChange = () => {},
	onExportClick = async () => {},
	onRefreshRequest = async () => {},
	isLoading = false,
	tableTitle = 'table',
	hasRowActions = false,
	rowActions,
	multipleRowActions,
	RowActionsComponent = null,
	MultipleRowActionsComponent = null,
	defaultFilters = null,
	model = null,
	columns = null,
}) => {
	const controlsCount =
		0 +
		(model.isFilterable() ? 1 : 0) +
		(model.isExportable() ? 1 : 0) +
		(model.isModifiable() ? 1 : 0) +
		(model.isRefreshable() ? 1 : 0);

	const { translate } = useTranslations();
	const [showFilters, setShowFilters] = useState(false);
	const [filters, setFilters] = useState({});
	const searchFilters = useDebounce(filters, 500);
	const [postFilters, setPostFilters] = useState({});
	const searchPostFilters = useDebounce(postFilters, 500);
	const [exportInProgress, setExportInProgress] = useState(false);
	const [displayColumns, setDisplayColumns] = useState(columns || model.getColumns());
	const [tableIsOverflowed, setTableIsOverflowed] = useState(false);

	const storageHandler = new StorageHandler();

	useEffect(() => {
		const getCustomColumns = async () => {
			const c_columns = await model.getCustomColumns();
			setDisplayColumns(c_columns);
		};

		getCustomColumns();
	}, []);

	useEffect(() => {
		setDisplayColumns(columns || model.getColumns());
	}, [columns]);

	const resetColumns = async () => {
		setDisplayColumns(columns || (await model.getColumns()));
		closeColumnsPicker();
		return await storageHandler.removeSchema(model.getModelName());
	};

	const columnChangeHandler = async (c_columns) => {
		c_columns.sort((a, b) => a.order - b.order);
		setDisplayColumns(c_columns);

		const dataToStore = c_columns.map((item) => {
			return {
				hidden: item.hidden,
				order: item.order,
				name: item.name,
			};
		});

		return await storageHandler.storeSchema(model.getModelName(), dataToStore);
	};

	const { openColumnsPicker, closeColumnsPicker } = TableColumns({
		columns: displayColumns,
		defaultColumns: model?.getColumns(),
		tableTitle: model.getPluralModelName(),
		onColumnsChange: columnChangeHandler,
		onReset: resetColumns,
	});

	function debounce(func, wait) {
		let timeout;
		return function (...args) {
			return new Promise((resolve, reject) => {
				const later = () => {
					clearTimeout(timeout);
					try {
						resolve(func(...args));
					} catch (error) {
						reject(error);
					}
				};
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
			});
		};
	}
	const debounceTableRequestChange = debounce(onTableRequestChange, 200);

	const _sendRequest = useCallback(
		(pgn = null, params = null, l_postFilters = null) => {
			if (pgn === null) pgn = { ...meta };
			if (params === null) params = searchFilters;
			if (l_postFilters === null) l_postFilters = searchPostFilters;

			if (defaultFilters) {
				Object.keys(defaultFilters).forEach((key) => {
					if (defaultFilters[key]) {
						params[key] = defaultFilters[key];
					}
				});
			}

			const toExcludeOnApply = model
				?.getColumns()
				.filter((item) => item.filter && item.filter?.isParam !== false)
				.filter((item) => item.filter && item.filter.toExcludeOnApply)
				.map((item) => item.filter.toExcludeOnApply)
				.flat();

			toExcludeOnApply.forEach((item) => {
				if (params[item.on]) {
					delete params[item.exclude];
				}
			});

			const paginationStr = buildPagination(pgn);
			const filterStr = buildFilterParams(params);

			debounceTableRequestChange(`${paginationStr}&${filterStr}`, sanitizeFilters(l_postFilters));
		},
		[meta, searchFilters, debounceTableRequestChange],
	);

	const changePagination = (key, value) => {
		const newPagination = { ...meta, [key]: value };
		if (key === 'size') newPagination['page'] = 1;
		_sendRequest(newPagination, null);
	};

	const sortChange = (sortKey, sortAsc) => {
		const newParams = { ...meta, sortBy: sortKey, sortAsc };
		_sendRequest(newParams, null);
	};

	useEffect(() => {
		_sendRequest(null, searchFilters);
	}, [searchFilters]);

	useEffect(() => {
		_sendRequest(null, searchFilters, searchPostFilters);
	}, [searchPostFilters]);

	useEffect(() => {
		_sendRequest(null, searchFilters);
	}, [defaultFilters]);

	const getExportData = () => {
		return buildExportParams(meta, searchFilters);
	};

	const headerVariants = {
		1: 'grid grid-cols-1  gap-4',
		2: 'grid grid-cols-2  gap-4',
		3: 'grid grid-cols-3  gap-4',
		4: 'flex flex-row  gap-4',
	};

	const horizontalScrollTip = `
	<div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">
	<div className="flex">
	  <div>
		<div className="font-bold">
		<i class="ri-information-line"></i>
		<b>To scroll horizontally:</b></div>
		<div className="text-sm">
		  <i>Keep the</i> <b>Shift</b> <i> button of <br/> your keyboard pressed</i> and <br/><u>Scroll the <b>mouse wheel<b/></u>
		</div>
	  </div>
	</div>
  </div>
	`;

	return (
		<div className={'flex flex-col'}>
			<div className={'flex sm:flex-col md:flex-col lg:flex-row flex-wrap'}>
				<div className='flex-1'>{controls}</div>
				<div className='flex justify-end items-end'>
					<div className='md:none flex flex-1'></div>
					{controlsCount > 0 && (
						<div className={`${headerVariants[controlsCount]}`}>
							{model.isRefreshable() && (
								<div onClick={() => onRefreshRequest()} className='flex flex-row justify-end'>
									<div className='cursor-pointer py-2 flex flex-row items-center opacity-75 hover:opacity-100'>
										<i className='ri-refresh-line'></i>
										<div className='ml-1 text-xs font-bold'>{translate('refresh')}</div>
									</div>
								</div>
							)}

							{model.isFilterable() && (
								<div onClick={() => setShowFilters((f) => !f)} className='flex flex-row justify-end'>
									<div className='cursor-pointer py-2 flex flex-row items-center opacity-75 hover:opacity-100'>
										<i className='ri-filter-3-line'></i>
										<div className='ml-1 text-xs font-bold'>{translate('filters')}</div>
									</div>
								</div>
							)}

							{model.isModifiable() ? (
								<div onClick={() => openColumnsPicker()} className='flex flex-row justify-end'>
									<div
										data-tooltip-id={`table-tooltip`}
										data-tooltip-html={tableIsOverflowed ? horizontalScrollTip : ''}
										data-tooltip-variant='info'
										data-tooltip-delay-show={1000}
										className='columns-picker-trigger-button cursor-pointer py-2 flex flex-row items-center justify-end opacity-75 hover:opacity-100'
									>
										<i className='ri-table-line'></i>
										<div className='ml-1 text-xs font-bold'>{translate('columns')}</div>
									</div>
								</div>
							) : (
								<></>
							)}
							{model.isExportable() ? (
								<div
									onClick={async () => {
										setExportInProgress(true);
										await onExportClick(getExportData());
										setExportInProgress(false);
									}}
									className='flex flex-row justify-end'
								>
									<div className='cursor-pointer py-2 flex flex-row items-center justify-end opacity-75 hover:opacity-100'>
										{exportInProgress ? (
											<>
												<i className='ri-loader-2-line animate-spin'></i>
												<div className='ml-1 text-xs font-bold'>
													{translate('progressing')}
													...
												</div>
											</>
										) : (
											<>
												<i className='ri-file-chart-line'></i>
												<div className='ml-1 text-xs font-bold'>{translate('export')}</div>
												<i className='ml-2 ri-arrow-down-s-line'></i>
											</>
										)}
									</div>
								</div>
							) : (
								<></>
							)}
						</div>
					)}
				</div>
			</div>

			<>
				<Table
					isLoading={isLoading}
					columns={displayColumns}
					model={model}
					data={data}
					onRowClick={onRowClick}
					onSortChange={sortChange}
					sortBy={meta?.sortBy}
					sortAsc={meta?.sortAsc}
					showFilters={showFilters}
					hasRowActions={hasRowActions}
					rowActions={rowActions}
					RowActionsComponent={RowActionsComponent}
					MultipleRowActionsComponent={MultipleRowActionsComponent}
					multipleRowActions={multipleRowActions}
					onFilterChange={(key, value) => {
						setFilters((f) => {
							return { ...f, [key]: value };
						});
					}}
					onPostFilterChange={(key, value) => {
						setPostFilters((f) => {
							return { ...f, [key]: value };
						});
					}}
					searchFilters={filters}
					onOverflowChange={(overflowed) => {
						setTableIsOverflowed(overflowed);
					}}
				/>
				<div className='flex flex-row justify-end mt-5'>
					<Pagination
						page={meta.page || 1}
						size={meta.size || 20}
						total={meta.total || 0}
						onPageChange={(newPage) => changePagination('page', newPage)}
						onPageSizeChange={(newPageSize) => changePagination('size', newPageSize)}
					/>
				</div>
			</>
		</div>
	);
};

TableWrapper.propTypes = {
	data: PropTypes.array,
	meta: PropTypes.shape({
		page: PropTypes.number,
		size: PropTypes.number,
		total: PropTypes.number,
		sortBy: PropTypes.string,
		sortAsc: PropTypes.bool,
	}),
	columns: PropTypes.array,
	model: PropTypes.shape({
		getColumns: PropTypes.func,
		isFilterable: PropTypes.func,
		isExportable: PropTypes.func,
		isModifiable: PropTypes.func,
		isRefreshable: PropTypes.func,
		areHeadersVisible: PropTypes.func,
		areSelectsVisible: PropTypes.func,
	}),
};

export default TableWrapper;
