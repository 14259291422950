import {
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
	useEffect,
} from "react";
import { createPortal } from "react-dom";

import { Button } from "components/ui/Input";

import Language from "./parts/Language";
import Password from "./parts/Password";
import TwoFa from "./parts/2Fa";
import AppAuth from "./parts/AppAuth";
import EmailTwoFa from "./parts/EmailAuth";
import { useTranslations } from "hooks";
import tokenService from "services/token/token.service";

import {
	updateSettings,
	changePassword,
	get2Fa,
	activate2Fa,
} from "../../services/settings";

import { useUser } from "hooks";

const STATE = {
	language: 0,
	password: 1,
	tfa: 2,
	code: 3,
	auth: 4,
	emailAuth: 5,
};

const ONBOARDING_STEPS = {
	SelectLanguage: 1,
	ChangePassword: 2,
	SetupTwoFa: 4,
};

const StateMap = {
	[ONBOARDING_STEPS.SelectLanguage]: STATE.language,
	[ONBOARDING_STEPS.ChangePassword]: STATE.password,
	[ONBOARDING_STEPS.SetupTwoFa]: STATE.tfa,
};

const OnboardingPortal = forwardRef((props, ref) => {
	const [show, setShow] = useState(false);
	const [data, setData] = useState([]);
	const [otp, setOtp] = useState({});
	const [currentState, setCurrentState] = useState(STATE.language);
	const onFinishCallback = useRef(null);
	const user = useUser();

	let settings = tokenService.getSettings();
	const { changeLanguage } = useTranslations();

	useEffect(() => {
		let foundStep = false;
		if (settings?.onboardingSteps && settings?.onboardingSteps.length > 0) {
			settings?.onboardingSteps.forEach((step) => {
				if (
					step.done === false &&
					StateMap[step.stepId] !== undefined &&
					!foundStep &&
					currentState < 4 // when it is on email auth or codeAuth do not  change the state
				) {
					setCurrentState(StateMap[step.stepId]);
					foundStep = true;
				}
			});
		}
	}, [settings]);

	const markStepAsDone = (stepId) => {
		if (settings?.onboardingSteps) {
			const updatedSteps = settings.onboardingSteps.map((step) => {
				if (step.stepId === stepId) {
					return { ...step, done: true };
				}
				return step;
			});
			const allStepsDone = updatedSteps.every((step) => step.done);
			if (allStepsDone) {
				tokenService.updateSettings("onboardingDone", true);
			}
			tokenService.updateSettings("onboardingSteps", updatedSteps);
		} else {
			console.warn("onboardingSteps not found in settings");
		}
	};

	const finishTwoFa = () => {
		markStepAsDone(ONBOARDING_STEPS.SetupTwoFa);
		closeAndReset();
	};

	const isStepDone = (stepId) =>
		settings?.onboardingSteps?.find((s) => s.stepId === stepId)?.done;

	const nextState = () => {
		switch (currentState) {
			case STATE.language:
				if (isStepDone(2)) {
					if (isStepDone(4)) {
						closeAndReset();
					} else {
						setCurrentState(STATE.tfa);
					}
				} else {
					setCurrentState(STATE.password);
				}
				markStepAsDone(ONBOARDING_STEPS.SelectLanguage);
				break;
			case STATE.password:
				if (isStepDone(4)) {
					closeAndReset();
				} else {
					setCurrentState(STATE.tfa);
				}
				markStepAsDone(ONBOARDING_STEPS.ChangePassword);
				break;
			default:
				break;
		}
	};

	const skipIsVisible = !settings?.onboardingSteps?.find(
		(s) => s.stepId === 4
	)?.isMandatory;

	const closeAndReset = () => {
		setShow(false);
		setData([]);
		setCurrentState(STATE.language);
		onFinishCallback.current();
		onFinishCallback.current = null;
	};

	const loadOtp = async (email = false) => {
		const otpRes = await get2Fa(email);
		if (!email) setOtp(otpRes.data);
	};

	useImperativeHandle(ref, () => ({
		callAction: (action, data, callback) => {
			switch (action) {
				case "open":
					setShow(true);
					onFinishCallback.current = callback;
					break;
				default:
					throw Error(
						`No action named ${action} for portal ${props.id}`
					);
			}
		},
	}));

	const renderState = () => {
		switch (currentState) {
			case STATE.language:
				return (
					<Language
						selectAndNext={(l) => {
							changeLanguage(l);
							updateSettings({ language: l });
							setData((d) => ({ ...d, language: l }));
							nextState();
						}}
					/>
				);
			case STATE.password:
				return (
					<Password
						isFinish={settings?.loginTwoFaPolicy === 1}
						selectAndNext={(p) => {
							changePassword(p, null, true);
							setData((d) => ({ ...d, newPassword: p }));
							if (settings?.loginTwoFaPolicy !== 1) {
								nextState();
							} else {
								closeAndReset();
							}
						}}
					/>
				);
			case STATE.tfa:
				return (
					<TwoFa
						selectAndNext={(method) => {
							loadOtp(method === "email");
							setCurrentState(
								method === "app" ? STATE.auth : STATE.emailAuth
							);
						}}
						skipIsVisible={skipIsVisible}
						skip={() => finishTwoFa()}
					/>
				);
			case STATE.auth:
				return (
					<AppAuth
						otpLink={otp?.qrCodeUri}
						register={async (code) => {
							return await activate2Fa(code, false);
						}}
						selectAndNext={() => finishTwoFa()}
					/>
				);
			case STATE.emailAuth:
				return (
					<EmailTwoFa
						email={user?.email}
						register={async (code) => {
							return await activate2Fa(code, true);
						}}
						selectAndNext={() => finishTwoFa()}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<>
			{show &&
				createPortal(
					<div
						// onClick={() => closeAndReset()}
						className="absolute z-50 top-0 bottom-0 right-0 left-0 bg-slate-800/50 flex justify-center items-center"
					>
						<div
							onClick={(e) => e.stopPropagation()}
							className="relative flex flex-col h-fit w-3/4 sm:w-2/3 md:w-3/5 lg:w-1/3 rounded-lg bg-white px-4 pt-4"
						>
							{renderState()}
						</div>
					</div>,
					document.body
				)}
		</>
	);
});

export default OnboardingPortal;
