import React, {
	useRef,
	useImperativeHandle,
	forwardRef,
	useState,
	useEffect,
} from "react";
import PropTypes from "prop-types";
import RadioElement from "./internal/Radio";
import Tooltip from "components/ui/Input/internal/Tooltip";
import { useTranslations } from "hooks";

const getLocationClass = (location) => {
	switch (location) {
		case "start":
			return "justify-start";
		case "center":
			return "justify-center";
		case "end":
			return "justify-end";
		default:
			return "justify-start";
	}
};

const Radio = forwardRef(
	(
		{
			name = "",
			options = [],
			required = false,
			selected = null,
			defaultSelected = null,
			defaultValue = null,
			getFirstItemAsDefault = true,
			disabled = false,
			location = "start",
			radioSize = "text-xl",
			className = "",
			onChange = () => {},
			alignment = "horizontal",
			title = null,
		},
		ref
	) => {
		const { translate } = useTranslations();
		const [activeValue, setActiveValue] = useState(
			defaultSelected
				? defaultSelected
				: getFirstItemAsDefault
				? options[0]?.value
				: null
		);

		useEffect(() => {
			const value = defaultSelected || defaultValue;

			setActiveValue(
				value ? value : getFirstItemAsDefault ? options[0]?.value : null
			);
		}, [defaultSelected, defaultValue]);

		const alignmentClass =
			alignment === "horizontal"
				? "flex space-x-5"
				: "flex-col -space-y-2";

		return (
			<Tooltip text={translate(title, true)}>
				<div
					className={`py-2 ${alignmentClass}  ${getLocationClass(
						location
					)} mr-5`}
				>
					{options.map((option, index) => (
						<RadioElement
							required={required}
							key={index}
							className={className}
							disabled={disabled}
							name={name}
							value={option.value}
							selected={
								selected !== null
									? selected === option.value
									: activeValue === option.value
							}
							label={option.label}
							radioSize={radioSize}
							onChange={(e) => {
								onChange(e);
								setActiveValue(option.value);
							}}
						/>
					))}
				</div>
			</Tooltip>
		);
	}
);

Radio.propTypes = {
	className: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })
	),
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	radioSize: PropTypes.string,
	defaultSelected: PropTypes.any,
	name: PropTypes.string,
	location: PropTypes.oneOf(["start", "center", "end"]),
	required: PropTypes.bool,
	selected: PropTypes.any,
	alignment: PropTypes.oneOf(["horizontal", "vertical"]),
	getFirstItemAsDefault: PropTypes.bool,
};

export default Radio;
