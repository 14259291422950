import { BadgedListColumn, IdColumn, NameColumn, StatusColumn } from 'core/columns';
import { DateColumn } from 'core/columns/baseColumns';
import BaseModel from 'core/models/BaseModel';

class OnlineEmployeeModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'onlineEmployee';
		this.pluralModelName = 'onlineEmployees';
	}
	initializeColumns() {
		this.columns.push(new IdColumn());
		this.columns.push(
			new NameColumn({
				label: 'name',
				filter: {
					type: 'text',
					key: 'Name',
				},
			}),
		);
		this.columns.push(new DateColumn('breakUntilOn'));
		this.columns.push(new DateColumn('lastTaskCompletedOn'));
		this.columns.push(
			new StatusColumn('status', { 1: 'lightBlue', 2: 'red', 3: 'purple', 4: 'orange', 5: 'purple' }),
		);
		this.columns.push(new BadgedListColumn('teams', { renderProps: { itemClassName: 'px-3' } }));

		this.assignColumnOrder();
	}

	areSelectsVisible() {
		return false;
	}

	isExportable() {
		return false;
	}
}

export default OnlineEmployeeModel;
