import React, { useEffect, useState } from "react";
import InputField from "modules/tasks/components/InputField";
import { Each } from "utils/Each";
import { FormEntry } from "components/form";
import FieldHelper from "modules/tasks/pages/Manage/pages/TaskTemplates/pages/TaskTemplates/utils/FieldHelper";
export const CustomFieldRenderer = ({
	fields,
	onRemoveField = () => {},
	taskGroupId,
}) => {
	const fieldHelper = FieldHelper({ fields });

	return (
		<Each
			of={fields}
			render={(field) => {
				return (
					<FormEntry required label={field.name}>
						<div className="flex flex-row ">
							<div className="flex w-11/12 ">
								<InputField
									name={`custom_input_${field.id}`}
									inputType={field.inputType?.id}
									defaultData={
										field.values
											? JSON.stringify(field.values)
											: null
									}
									noAllAtSubmit={true}
									hasAllOption={false}
									options={
										field?.value
											? fieldHelper.matchOptions(
													field?.value
											  )
											: []
									}
									isRequired={true}
									isDisabled={false}
									parentId={taskGroupId}
								/>
							</div>
							<div className="flex w-1/12 justify-center items-center">
								<div
									onClick={(e) => {
										e.preventDefault();
										onRemoveField(field);
									}}
									className="text-xl  cursor-pointer opacity-50 hover:opacity-80"
								>
									<i className="ri-delete-bin-line"></i>
								</div>
							</div>
						</div>
					</FormEntry>
				);
			}}
		/>
	);
};
export default CustomFieldRenderer;
