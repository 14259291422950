const Conf = {
	settings: {
		show: true,
		to: "/settings",
		label: "settings",
		id: "settings",
		icon: "ri-settings-4-line",
	},
	modules: [
		{
			label: "tasks",
			icon: "ri-dashboard-fill",
			to: "/tasks",
			id: "tasks",
		},
		{
			label: "persons",
			icon: "ri-file-user-line",
			to: "/persons",
			id: "persons",
		},
		{
			label: "locations",
			icon: "ri-hotel-line",
			to: "/locations",
			id: "locations",
		},
		// {
		// 	label: "assets",
		// 	icon: "ri-book-2-fill",
		// 	to: "/assets",
		// 	id: "assets",
		// },
		// {
		// 	label: "reports",
		// 	icon: "ri-bar-chart-fill",
		// 	to: "/reports",
		// 	id: "reports",
		// },
	],
};

export default Conf;
