import React, { useRef } from "react";
import { useTranslations } from "hooks";

const ICONS = {
	default: "ri-checkbox-blank-circle-line text-gray-500",
	active: "ri-radio-button-line",
};

const RadioElement = ({
	className,
	disabled,
	name,
	value,
	selected,
	label,
	radioSize,
	required,
	onChange = () => {},
}) => {
	const radioRef = useRef();
	const { translate } = useTranslations();

	const handleDivClick = (e) => {
		const newEvent = {
			...e,
			target: radioRef.current,
			currentTarget: radioRef.current,
		};
		onChange(newEvent);
	};
	return (
		<div className="flex items-center">
			<div
				onClick={!disabled ? handleDivClick : null}
				className={`relative ${
					disabled ? "cursor-not-allowed" : "cursor-pointer"
				} flex flex-row items-center p-1 ${className}`}
			>
				<i
					className={`${radioSize} ${
						selected ? ICONS.active : ICONS.default
					} ${disabled && "text-gray-300"}`}
				/>
				<div className={`text-sm ml-2 ${disabled && "opacity-50"}`}>
					{typeof label === "string" ? translate(label, true) : label}
				</div>
				<input
					required={required}
					ref={radioRef}
					type="radio"
					disabled={disabled}
					className="sr-only"
					value={value}
					name={name}
					checked={selected}
					onChange={() => {}}
				/>
			</div>
		</div>
	);
};

export default RadioElement;
