import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
	useContext,
} from "react";
import TaskGroupService from "../../../../../Manage/services";
import { useCrud, useTranslations } from "hooks";

import { stringToColorHex } from "utils";
import TaskGroupItem from "./TaskGroupItem";

const TaskTemplatePicker = forwardRef((props, ref) => {
	const { translate } = useTranslations();
	const taskGroupService = new TaskGroupService();
	const { getAll: getAllTaskGroups } = useCrud(taskGroupService);
	const [taskGroups, setTaskGroups] = useState([]);
	const [activeTaskGroup, setActiveTaskGroup] = useState(null);

	const pickTaskTemplate = (taskTemplate, taskTemplateLabel) => {
		if (props.pickTaskTemplate)
			props.pickTaskTemplate(
				taskTemplate,
				activeTaskGroup,
				taskTemplateLabel
			);
	};

	const fetchTaskGroups = async () => {
		getAllTaskGroups().then((res) => {
			setTaskGroups(res.data);
		});
	};

	useEffect(() => {
		fetchTaskGroups();
	}, []);

	return (
		<div>
			{/* //information text to click on a task group in order to select a task TaskTemplate */}
			<div className=" text-gray-500 mb-2 space-x-2 pl-2">
				<i class="ri-information-line"></i>
				<span className="text-xs">
					{translate("clickOnTaskGroupToSelectTaskTemplate")}
				</span>
			</div>
			{taskGroups.map((taskGroup) => (
				<TaskGroupItem
					key={taskGroup.id}
					id={taskGroup.id}
					name={taskGroup.name}
					icon={taskGroup.icon}
					moduleName={taskGroup.module.name}
					active={
						activeTaskGroup === taskGroup.id ||
						taskGroups?.length === 1
					}
					color={taskGroup.color}
					onTaskGroupSelect={(id) => {
						setActiveTaskGroup(id);
					}}
					onSelect={(taskTemplate, taskTemplateLabel) => {
						pickTaskTemplate(taskTemplate, taskTemplateLabel);
					}}
				/>
			))}
		</div>
	);
});
export default TaskTemplatePicker;
