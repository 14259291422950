import { useEffect, useState, useRef } from "react";
import { useTranslations } from "hooks";

const OptionInput = ({ name, required, defaultValue, onChange = () => {} }) => {
	const [options, setOptions] = useState([]); // state to hold the options
	const inputRef = useRef(null);
	const [editMode, setEditMode] = useState(false);
	const [editIndex, setEditIndex] = useState(null);

	const { translate } = useTranslations();

	useEffect(() => {
		if (defaultValue) {
			if (typeof defaultValue === "string") {
				setOptions(JSON.parse(defaultValue));
			} else {
				setOptions(defaultValue);
			}
		}
	}, [defaultValue]);

	const submitOption = () => {
		const option = inputRef.current.value;
		if (!option) return;

		if (editMode) {
			const newOptions = [...options];
			newOptions[editIndex] = option;
			setOptions(newOptions);
			onChange(newOptions);
			setEditMode(false);
			setEditIndex(false);
			inputRef.current.value = "";
		} else {
			const l_options = [...options, option];
			setOptions(l_options);
			inputRef.current.value = "";
			onChange(l_options);
		}
	};

	const cancelEdit = () => {
		setEditMode(false);
		setEditIndex(null);
		inputRef.current.value = "";
	};

	const editOption = (index, option) => {
		setEditMode(true);
		inputRef.current.value = option;
		setEditIndex(index);
	};

	const deleteOption = (index) => {
		const newOptions = [...options];
		newOptions.splice(index, 1);
		setOptions(newOptions);
		onChange(newOptions);
	};

	return (
		<div className="relative bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 my-1 cursor-pointer">
			<input
				type="text"
				name={name}
				required={required}
				className="sr-only"
				value={options?.length > 0 ? JSON.stringify(options) : ""}
				onChange={() => {}}
			/>
			{options.map((option, index) => (
				<div
					key={index}
					title={translate("editThisOption")}
					className="flex justify-between items-center mb-2 border-b border-gray-200 pb-1 px-1 opacity-60 hover:opacity-100"
				>
					<span
						className="cursor-pointer"
						onClick={() => {
							editOption(index, option);
						}}
					>
						{option}
					</span>
					<div
						onClick={(e) => {
							e.stopPropagation();
							deleteOption(index);
						}}
					>
						<i className="ri-delete-bin-line text-md "></i>
					</div>
				</div>
			))}

			<div className="relative">
				<input
					ref={inputRef}
					placeholder={
						editMode
							? `${translate("editThisOption")}...`
							: `${translate("newOption")}...`
					}
					className="mr-2 pt-2 rounded-md border border-gray-300 w-full p-1.5 pl-3 pr-10"
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							submitOption();
						}
					}}
				/>
				<button
					title={
						editMode
							? translate("editThisOption")
							: translate("addOption")
					}
					type="button"
					onClick={() => {
						submitOption();
					}}
					className="absolute inset-y-0 right-0 pr-3 flex items-center"
				>
					{editMode ? (
						<div className="flex space-x-2">
							<i className=" ri-check-line text-xl opacity-40 hover:opacity-100"></i>
							<i
								onClick={(e) => {
									e.stopPropagation();
									cancelEdit();
								}}
								className=" ri-close-line text-xl opacity-40 hover:opacity-100"
							></i>
						</div>
					) : (
						<i className=" ri-add-circle-line text-lg opacity-40 hover:opacity-100"></i>
					)}
				</button>
			</div>
		</div>
	);
};
export default OptionInput;
