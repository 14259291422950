import { localStorageKeys } from 'config';
import TenantService from '../services';

const TenantUtils = () => {
	const service = new TenantService();

	const getSettings = async (fromLocalStorage = true) => {
		let settings = localStorage.getItem(localStorageKeys.TENANT_SETTINGS_STORAGE_KEY);
		if (settings && fromLocalStorage && JSON.parse(settings)) {
			return JSON.parse(settings);
		} else {
			const res = await service.getSettings();
			localStorage.setItem(localStorageKeys.TENANT_SETTINGS_STORAGE_KEY, JSON.stringify(res?.data));
			return res?.data;
		}
	};

	const getEnum = async (key) => {
		let enums = localStorage.getItem(localStorageKeys.TENANT_ENUMS_STORAGE_KEY);

		if (enums && JSON.parse(enums)[key]) {
			return JSON.parse(enums)[key];
		} else {
			if (localStorage.getItem(localStorageKeys.TENANT_ENUMS_STORAGE_KEY + '-loading')) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				enums = localStorage.getItem(localStorageKeys.TENANT_ENUMS_STORAGE_KEY);
				if (enums && JSON.parse(enums)[key]) {
					return JSON.parse(enums)[key];
				}
			}
			localStorage.setItem(localStorageKeys.TENANT_ENUMS_STORAGE_KEY + '-loading', true);
			const res = await service.getEnum(key);
			let newEnums = JSON.parse(enums) || {};
			newEnums[key] = res.data;
			localStorage.setItem(localStorageKeys.TENANT_ENUMS_STORAGE_KEY, JSON.stringify(newEnums));
			localStorage.removeItem(localStorageKeys.TENANT_ENUMS_STORAGE_KEY + '-loading');

			return res.data;
		}
	};

	const getConstants = async () => {
		let constants = localStorage.getItem(localStorageKeys.TENANT_CONSTANTS_STORAGE_KEY);
		if (constants && JSON.parse(constants)) {
			return JSON.parse(constants);
		} else {
			const res = await service.getConstants();
			localStorage.setItem(localStorageKeys.TENANT_CONSTANTS_STORAGE_KEY, JSON.stringify(res?.data));
			return res?.data;
		}
	};

	const getVersion = async () => {
		let version = localStorage.getItem(localStorageKeys.TENANT_VERSION_STORAGE_KEY);
		if (version && JSON.parse(version)) {
			return JSON.parse(version);
		} else {
			const res = await service.getVersion();
			localStorage.setItem(localStorageKeys.TENANT_VERSION_STORAGE_KEY, JSON.stringify(res?.data));
			return res?.data;
		}
	};

	const getInputTypes = async () => {
		let inputTypes = localStorage.getItem(localStorageKeys.TENANT_INPUT_TYPES_STORAGE_KEY);
		if (inputTypes && JSON.parse(inputTypes)) {
			return JSON.parse(inputTypes);
		} else {
			const res = await service.getInputTypes();
			localStorage.setItem(localStorageKeys.TENANT_INPUT_TYPES_STORAGE_KEY, JSON.stringify(res?.data));
			return res?.data;
		}
	};

	return {
		getSettings,

		getEnum,
		getConstants,
		getVersion,
		getInputTypes,
	};
};

const utils = TenantUtils();

export default utils;
