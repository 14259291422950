import AbstractService from "services/Service";

const endpoint = "tenant/custom-fields";

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	mapData(data) {
		const { id, taskGroupIds, InputType, ...rest } = data;
		return {
			...rest,
			taskGroupIds: taskGroupIds
				? taskGroupIds.split(",").map(Number)
				: [],

			inputType: InputType ? parseInt(InputType) : null,
		};
	}
}
export default Service;
