import { useTranslations } from "hooks";

import NewNode from "./NewNode";

import { COLORS, getTranslatedName } from "./utils";
import NodeLoader from "./NodeLoader";

const Node = ({
	name,
	level = -1,
	permissions,
	rootLevel = 6,
	expand = false,
	onExpandToggle = () => {},
	onEditOpen = () => {},
	onDelete = () => {},
	onAddOpen = () => {},
	onMatrixOpen = () => {},
	loading = false,
	children,
}) => {
	// ================================================
	// CONSTANTS
	// ================================================
	const oglevel = level;
	level = rootLevel - level;

	// ================================================
	// HOOKS
	// ================================================
	const { translate } = useTranslations();

	// ================================================
	// UTILS
	// ================================================
	const position = 100 - 4 * level;

	// ================================================
	// COMPONENT
	// ================================================
	return (
		<div className="flex flex-col items-end w-[100%]">
			<div
				style={{ width: `${position}%` }}
				onClick={() => level !== 5 && onExpandToggle()}
				className={`group flex flex-row items-center mb-2 overflow-hidden cursor-pointer hover:border-sky-500 bg-white border border-gray-200 rounded`}
			>
				<div className={`h-full w-[5px] ${COLORS[oglevel]}`} />
				<div className="py-2 flex flex-row w-full items-center">
					{level !== 5 && (
						<i
							className={`${
								expand
									? "ri-checkbox-indeterminate-line"
									: "ri-add-box-line"
							} ml-3 text-xl`}
						></i>
					)}

					<div className="ml-3 flex flex-1 w-full flex-col">
						<div className="text-xs text-slate-500">
							{getTranslatedName(translate, oglevel)}
						</div>
						<div
							onClick={(e) => {
								permissions.Update && onEditOpen();
								e.stopPropagation();
							}}
							className="hover:underline font-medium"
						>
							{name}
						</div>
					</div>
					<div
						className="hidden px-4 group-hover:flex flex-row gap-4 text-right"
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						{oglevel === 4 && (
							<div
								className="text-sm p-2 text-slate-600 cursor-pointer opacity-50 hover:opacity-75"
								onClick={onMatrixOpen}
							>
								<i className="ri-grid-fill mr-2"></i>
								Distance Matrix
							</div>
						)}
						{permissions.Delete && (
							<div
								className="text-sm p-2 text-red-600 cursor-pointer opacity-25 hover:opacity-75"
								onClick={onDelete}
							>
								<i className="ri-delete-bin-line mr-2"></i>
								{translate("remove")}
							</div>
						)}
					</div>
				</div>
			</div>
			{expand ? (
				loading ? (
					<NodeLoader level={level + 1} rootLevel={rootLevel} />
				) : (
					<>
						{children}
						{permissions?.Create && (
							<NewNode
								onClick={onAddOpen}
								level={oglevel - 1}
								rootLevel={rootLevel}
							/>
						)}
					</>
				)
			) : (
				<></>
			)}
		</div>
	);
};

export default Node;
