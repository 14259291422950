import {
	useImperativeHandle,
	forwardRef,
	useRef,
	useState,
	useEffect,
	useCallback,
} from "react";
import { useTranslations } from "hooks";

import FormBuilder from "components/form/FormBuilder";

import { addressFormFields } from "../../../models/address.model";
import { getRoomTypes } from "modules/addresses/services/addresses";
import {
	Form,
	FormDivider,
	FormEntry,
	InternalFormDivider,
} from "components/form";
import { Input } from "components/ui/Input";

const AddressForm = forwardRef((props, ref) => {
	const { translate } = useTranslations();
	const formRef = useRef(null);

	const [values, setValues] = useState({});
	const [siblings, setSiblings] = useState(null);
	const [roomTypes, setRoomTypes] = useState([]);

	useImperativeHandle(ref, () => ({
		getData: () => null,
		clear: () => formRef.current?.clear(),
		runSubmit: () => formRef.current?.submit(),
	}));

	// -----------------------------------
	// Effects
	// -----------------------------------
	const loadData = useCallback(async () => {
		// -----------------------------------
		// Edit Data
		// -----------------------------------
		if (props.isEdit) {
			const res = await props.load(props.id);
			const data = res.data;

			const vals = {
				name: data.name,
			};

			let siblings = null;

			if (props.level === 3 || props.level === 4) {
				siblings = [];
				data.distances?.forEach((dist) => {
					vals[`sibling:${dist.siblingToId}`] = dist.distance;
					siblings.push({
						id: dist.siblingToId,
						name: dist.siblingToName,
					});
				});
				setSiblings(siblings);
			}

			if (props.level === 2) {
				vals["RoomTypeId"] = parseInt(data.roomType.id, 10);
			}

			if ("externalId" in data) {
				vals["externalId"] = data.externalId;
			}

			setValues(vals);
		}

		// -----------------------------------
		// General Data
		// -----------------------------------
		if (props.level === 2) {
			const rt = await getRoomTypes();
			setRoomTypes(
				rt.data.map((rtt) => ({ value: rtt.id, label: rtt.name }))
			);
		}
	}, [props]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	return (
		<>
			<div className="w-full h-100 pb-40 overflow-y-auto">
				{props.isEdit && (
					<>
						<Form>
							<InternalFormDivider className="">
								DETAILS
							</InternalFormDivider>
							<FormEntry label="ID">
								<Input
									type="text"
									name="id-placeholder"
									value={props.id}
									disabled
									className="text-right"
								/>
							</FormEntry>
						</Form>
					</>
				)}
				<FormBuilder
					ref={formRef}
					schema={addressFormFields(
						translate,
						props.type,
						props.level,
						props.siblings || siblings || []
					)}
					extraProps={{ roomTypes }}
					values={values || {}}
					onSubmit={(data) => props.submitCallback(data)}
				/>
			</div>
		</>
	);
});

export default AddressForm;
