import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
} from "react";
import { Loading } from "components/ui/Interactive";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import { useAside, useTranslations, useCrud } from "hooks";
import { CheckBox, Input, MultipleSelect } from "components/ui/Input";
import { Link } from "react-router-dom";

const UrgencyForm = forwardRef((props, ref) => {
	const { getOne } = useCrud(props.service);

	const [data, setData] = useState([]);
	const myForm = useRef(null);

	const [loading, setLoading] = useState(true);

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className="w-full h-100 pb-10 overflow-y-visible">
				<Form
					ref={myForm}
					onSubmit={(e) => {
						e.preventDefault();
					}}
				>
					<input type="hidden" name="id" value={data?.id || false} />
					<FormEntry label="name" required={true}>
						<Input
							type="text"
							placeholder={"name"}
							required={true}
							name="name"
							defaultValue={data?.name}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default UrgencyForm;
