import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { CheckBox, Input, MultipleSelect } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useAside, useCrud, useTranslations } from 'hooks';
import UserProfileService from 'modules/persons/pages/Users/pages/Users/pages/UserProfiles/services';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const AdConfigurationForm = forwardRef((props, ref) => {
	const { asideBuilder } = useAside();
	const { getOne } = useCrud(props.service);
	const { translate } = useTranslations();
	const [data, setData] = useState([]);
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);
	const [userProfileOptions, setUserProfileOptions] = useState([]);

	const userProfileService = new UserProfileService();

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);

				setLoading(false);
			});
		} else {
			setLoading(false);
		}

		userProfileService.getOptionsList().then((res) => {
			setUserProfileOptions(res.data);
		});
	};

	useEffect(() => {
		initiateData();
	}, []);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	const closeAside = () => {
		asideBuilder.reset();
		asideBuilder.setOpen(false);
	};

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />
					<InternalFormDivider>{translate('generalInformation')}</InternalFormDivider>
					<FormEntry label='name' required={true}>
						<Input
							type='text'
							placeholder={'name'}
							required={true}
							name='name'
							defaultValue={data?.name || ''}
						/>
					</FormEntry>
					<InternalFormDivider>{translate('connectedProfiles')}</InternalFormDivider>
					<FormEntry
						label='userProfiles'
						required={true}
						helpText={
							<div>
								<div align='right' className='pt-5 '>
									<Link
										onClick={() => closeAside()}
										className='text-sm'
										to='/persons/users/users#newUserProfile'
									>
										{' + '}
										{translate('addUserProfile')}
									</Link>
								</div>
							</div>
						}
					>
						<MultipleSelect
							name='userProfileIds'
							options={userProfileOptions}
							value={data?.userProfileIds}
							noAllAtSubmit={true}
							hasAllOption={false}
						/>
					</FormEntry>
					<FormEntry label={'blockNormalLogin'}>
						<CheckBox
							name='blockNormalLogin'
							selected={data?.blockNormalLogin || false}
							label={'blockNormalLogin'}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});
export default AdConfigurationForm;
