import {
	forwardRef,
	useEffect,
	useState,
	Suspense,
	useRef,
	useImperativeHandle,
	useContext,
} from "react";
import { useTranslations, useCrud } from "hooks";
import { Loading } from "components/ui/Interactive";
import { FormEntry, Form, InternalFormDivider } from "components/form";
import { Input, Radio, ReSelect, TextArea } from "components/ui/Input";

const DocumentationForm = forwardRef((props, ref) => {
	const { getOne } = useCrud(props.service);
	const { translate } = useTranslations();

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const myForm = useRef(null);
	const [source, setSource] = useState(1);

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setSource(res.source);
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
	};

	useImperativeHandle(ref, () => ({
		getData,
		clear: resetData,
	}));

	useEffect(() => {
		initiateData();
	}, []);

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<div>Loading...</div>}>
			<div className="w-full h-100 pb-10 overflow-y-visible">
				<Form ref={myForm}>
					<InternalFormDivider>
						{translate("configuration")}
					</InternalFormDivider>
					<input type="hidden" name="id" value={data?.id || false} />
					<FormEntry label="name" required>
						<Input
							type="text"
							name="name"
							defaultValue={data?.name || ""}
							required={true}
							placeholder={"name"}
						/>
					</FormEntry>
					<FormEntry label="type" required>
						<Radio
							name={"source"}
							options={[
								{ label: "freeText", value: 1 },
								{ label: "externalLink", value: 2 },
							]}
							defaultSelected={source}
							onChange={(e) =>
								setSource(parseInt(e.target.value))
							}
							required={true}
						/>
					</FormEntry>
					{source === 1 ? (
						<FormEntry label="text" required>
							<TextArea
								rows={6}
								placeholder={"documentation"}
								required={true}
								name="text"
								defaultValue={data?.text || ""}
							/>
						</FormEntry>
					) : (
						<FormEntry label="linkUrl" required>
							<Input
								type="url"
								name="text"
								defaultValue={data?.text || ""}
								required={true}
								placeholder={"linkUrl"}
							/>
						</FormEntry>
					)}
				</Form>
			</div>
		</Suspense>
	);
});

export default DocumentationForm;
