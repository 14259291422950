import { useState, useEffect } from "react";
import { useTranslations } from "hooks";
import { ReSelect } from "components/ui/Input";
import toast from "react-hot-toast";
const Item = ({
	department,
	index,
	options = [],
	disabled = false,
	onRemove = () => {},
	open = true,
	setOpen = () => {},
	inputPrefix = "",
}) => {
	const { translate } = useTranslations();

	const [toDepartments, setToDepartments] = useState([]);

	useEffect(() => {
		if (department) {
			if (department?.departmentsTo) {
				setToDepartments(
					department?.departmentsTo?.map((departmentTo) => {
						const departmentOption = options.find(
							(option) => option.value === departmentTo?.id
						);

						return {
							...departmentTo,
							value: departmentOption?.value,
							label: departmentOption?.label,
						};
					})
				);
			} else {
				setToDepartments([department]);
			}
		}
	}, [department]);

	const removeToDepartment = (index) => {
		//at least one department should be set
		if (toDepartments.length === 1) {
			toast(translate("atLeastOneDepartmentShouldBeSet"), {
				duration: 2000,
				icon: "❗️",
			});
			return;
		}

		setToDepartments((prev) => {
			const newDepartments = [...prev];
			newDepartments.splice(index, 1);
			return newDepartments;
		});
	};

	const setAllToDepartments = () => {
		setToDepartments(options);
	};

	return (
		<div
			className={`bg-gray-50 border relative border-gray-300  text-gray-900 text-sm rounded-lg block w-full p-2.5 my-1`}
		>
			<input
				type="hidden"
				name={`${inputPrefix}departments[${index}].departmentId`}
				value={department?.value}
			/>
			{toDepartments?.map((departmentTo, depIndex) => (
				<input
					type="hidden"
					name={`${inputPrefix}departments[${index}].departmentToIds[${depIndex}].id`}
					value={departmentTo?.value}
				/>
			))}
			<div key={index}>
				<div className=" flex justify-between items-center cursor-pointer">
					<div onClick={() => setOpen(!open)} className="w-4/5">
						{department.label}
					</div>
					<div>
						<button
							className="px-1 py-1"
							disabled={disabled}
							type="button"
							onClick={(e) => {
								onRemove(index);
							}}
						>
							<i className="ri-delete-bin-line"></i>
						</button>
					</div>
				</div>

				{open && (
					<div className={` pb-1 `}>
						<div className="pl-2 pt-2 pb-2">
							{toDepartments?.map((departmentTo, depIndex) => (
								<div
									key={depIndex}
									className="flex items-center justify-between pt-1"
								>
									<div className="bg-gray-200 flex justify-between w-full text-gray-600 px-3 py-0.5 rounded-md">
										<div>{departmentTo.label}</div>
										<div>
											<button
												disabled={disabled}
												type="button"
												onClick={() =>
													removeToDepartment(depIndex)
												}
											>
												<i class="ri-close-circle-line"></i>
											</button>
										</div>
									</div>
								</div>
							))}
							<ReSelect
								className="mt-2"
								small={true}
								disabled={disabled}
								// options={options}
								options={options.filter((option) =>
									toDepartments?.every(
										(department) =>
											department.value !== option.value
									)
								)}
								placeholder={translate("selectDepartment")}
								onSelect={(selected) => {
									setToDepartments((prev) => {
										const departmentToAdd = options.filter(
											(item) => selected === item.value
										)[0];

										// Check if the department is already in the array
										if (
											prev?.some(
												(department) =>
													department.value ===
													departmentToAdd.value
											)
										) {
											// If it is, return the previous state
											return prev;
										}

										// If it's not, add the department to the array
										return [...prev, departmentToAdd];
									});
								}}
							/>
						</div>
						{toDepartments && toDepartments.length < 2 && (
							<div
								className=" pl-4 pb-2  text-red-700 opacity-30 hover:opacity-80 cursor-pointer text-xs "
								onClick={setAllToDepartments}
							>
								<i class="ri-play-list-add-fill pr-2"></i>
								{translate(
									"selectAsDefaultAllPossibleDepartments"
								)}
							</div>
						)}
					</div>
				)}

				<div
					className={`flex absolute w-full bottom-1 justify-center items-center cursor-pointer -my-1 rounded-md ${
						open ? "hover:bg-gray-100" : "bg-none"
					}  transition-colors duration-200 ease-in-out`}
					onClick={() => setOpen(!open)}
				>
					<i
						className={`ri-arrow-down-s-line text-gray-500 transition-transform duration-200  ease-in-out ${
							open ? "transform rotate-180" : ""
						}`}
					></i>
				</div>
			</div>
		</div>
	);
};

export default Item;
